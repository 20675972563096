/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import moment from "moment"
import {SSOLicenseCount} from "../../../../../types/types"
import {NewSSOEntitiesAndGroupMaster, NewSSOEntitiesAndGroupMasterErrors} from "../components/SSOEntityTypes"

export interface AddNewSSOEntityState {
    entity: NewSSOEntitiesAndGroupMaster,
    errors: NewSSOEntitiesAndGroupMasterErrors
}

export const initialSSOEntity: NewSSOEntitiesAndGroupMaster = {
    name: null,
    testingStart: null,
    testingEnd: null,
    termStart: null,
    termEnd: null,
    ssoSystem: null,
    oppNumber: null,
    ssoId:  null,
    oauth: null,
    appId: null,
    licensesSold: [],
    currentTerm: null,
    parentGroupId: null,
    districtGroupName: null,
    districtGroupType: null,
    customerGroupName: null,
    customerGroupType: null
}

export const initialNewSsoEntityErrors: NewSSOEntitiesAndGroupMasterErrors = {
    nameError: null,
    testingStartError: null,
    testingEndError: null,
    termStartError: null,
    termEndError: null,
    ssoSystemError: null,
    oppNumberError: null,
    ssoIdError: null,
    oauthError: null,
    appIdError:  null,
    licensesSoldError: null,
    currentTermError: null,
    parentGroupIdError: null,
    districtGroupNameError: null,
    districtGroupTypeError: null,
    customerGroupNameError: null,
    customerGroupTypeError: null
}


export type NewSSOEntityUpdateAction =
    | { type: "UPDATE_STRING_FIELD", fieldName: keyof NewSSOEntitiesAndGroupMaster, payload: string | null}
    | { type: "UPDATE_NUMBER_FIELD", fieldName: keyof NewSSOEntitiesAndGroupMaster, payload: number | null}
    | { type: "SET_DATE_VALUE", fieldName: keyof NewSSOEntitiesAndGroupMaster, payload: moment.Moment | null }
    | { type: "ADD_LICENSE", payload: SSOLicenseCount}
    | { type: "REMOVE_LICENSE", payload: string }
    | { type: "CLEAR_DATA" }
    | { type: "SET_ERROR_VALUE", fieldName: keyof NewSSOEntitiesAndGroupMasterErrors, payload: string | null}
    | { type: "CLEAR_ALL_ERRORS" }

export const newSSOEntityReducer = (curState: AddNewSSOEntityState, action: NewSSOEntityUpdateAction): AddNewSSOEntityState => {
    switch (action.type) {
        case "SET_DATE_VALUE":
            if (moment.isMoment(action.payload) || action.payload === null) {
                const errorFieldNameDate = `${action.fieldName}Error`
                // const utcMoment = moment.isMoment(action.payload) ? action.payload.utc() : null
                return {
                    ...curState,
                    entity: {...curState.entity, [action.fieldName]: action.payload},
                    // entity: {...curState.entity, [action.fieldName]: utcMoment},
                    errors: {...curState.errors, [errorFieldNameDate]: null}
                }
            }
            return curState
        case "UPDATE_STRING_FIELD":
            const errorFieldNameString = `${action.fieldName}Error`
            if (typeof action.payload === "string" || (typeof action.payload === "object" && action.payload === null)) {
                return {
                    ...curState,
                    entity: {...curState.entity, [action.fieldName]: action.payload},
                    errors: {...curState.errors, [errorFieldNameString]: null}
                }
            }
            return curState
        case "UPDATE_NUMBER_FIELD":
            const errorFieldNameNumber = `${action.fieldName}Error`
            if (typeof action.payload  === "number") {
                return {
                    ...curState,
                    entity: {...curState.entity, [action.fieldName]: action.payload},
                    errors: {...curState.errors, [errorFieldNameNumber]: null}
                }
            }
            return curState
        case "ADD_LICENSE":
            return {
                ...curState,
                entity: {...curState.entity, licensesSold: [...curState.entity.licensesSold, action.payload]},
                errors: {...curState.errors, licensesSoldError: null}
            }
        case "REMOVE_LICENSE":
            const curLicense = curState.entity.licensesSold.filter(pp => pp.productPackage !== action.payload)
            return {
                ...curState,
                entity: {...curState.entity, licensesSold: curLicense},
                errors: {...curState.errors, licensesSoldError: null}
            }
        case "CLEAR_DATA":
            return {
                entity: initialSSOEntity,
                errors: initialNewSsoEntityErrors
            }
        case "SET_ERROR_VALUE":
            return {
                ...curState,
                errors: {...curState.errors, [action.fieldName]: action.payload}
            }
        case "CLEAR_ALL_ERRORS":
            return {
                entity: {...curState.entity},
                errors: initialNewSsoEntityErrors
            }
        default:
            return curState
    }
}
