/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import CancelIcon from "@material-ui/icons/Cancel"
import SaveIcon from "@material-ui/icons/Save"
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel, MenuItem,
    Select, SelectChangeEvent,
    TextField
} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers-pro"
import moment from "moment"
import React, {useCallback, useEffect, useReducer, useState} from "react"
import {GroupType, ManageGroupsGroupMasterCronDTO} from "../../../../../app/common/group/models/GroupMaster"
import {messageStore} from "../../../../../app/common/messages/MessageStore"
import {SSOLicenseCount} from "../../../../../types/types"
import SSOEntityToolApi from "../SSOEntityToolApi"
import {
    AddNewSSOEntityState,
    initialNewSsoEntityErrors,
    initialSSOEntity,
    newSSOEntityReducer
} from "../utilities/AddNewSSOEntityReducer"
import {validateFormData} from "../utilities/UtilityFunctions"
import LicensesComponent from "./LicensesComponent"
import {NewSSOEntitiesAndGroupMaster, SSO_ENTITY_TYPES, SSOEntitiesAndGroupMastersResults} from "./SSOEntityTypes"

interface SSOEntityAddNewEntityModalProps {
    open: boolean,
    onClose: () => void,
    onSave: (newData: SSOEntitiesAndGroupMastersResults) => void
}

const initialStateObject: AddNewSSOEntityState = {
    entity: initialSSOEntity,
    errors: initialNewSsoEntityErrors
}

const SSOEntityAddNewEntityModal: React.FC<SSOEntityAddNewEntityModalProps> = (props) => {
    const {open, onClose, onSave} = props

    const [listOfStates, setListOfStates] = useState<ManageGroupsGroupMasterCronDTO[]>([])
    const [newSSOEntity, dispatch] = useReducer(newSSOEntityReducer, initialStateObject)


    useEffect(() => {
        getStateGroups()
    }, [])

/******************************* Functions from Parent START **********************************************************/

    const handleClose = useCallback(() => {
        dispatch({ type: "CLEAR_DATA" })
        onClose()
    }, [])

    // FROM PARENT
    const handleSaveNewEntity = useCallback(() => {
        // TODO: Validate form fields
        const isValid = validateFormData(newSSOEntity, dispatch)
        // TODO: Send data to API and wait for success or set errors
        if (isValid) {
            console.log("Sending new entity to API")
            // TODO: if no errors, close modal and update parent
            SSOEntityToolApi.createNewSSOEntityWithGroups(newSSOEntity.entity).then(
                (resp) => {
                    // TODO: handle parent update
                    onSave(resp)
                    handleClose()

                },
                (err) => {
                    messageStore.setErrorMessage(`Error Saving new SSO Entity: ${err.message}`)
                }
            )

        }

    }, [newSSOEntity])

    /******************************* Functions from Parent END **********************************************************/

    const getStateGroups = () => {
        console.log("Making api call")
        SSOEntityToolApi.getGroupMasterByTypeAndSsoId(GroupType.STATE, null).then((resp: ManageGroupsGroupMasterCronDTO[]) => {
            setListOfStates(resp)
        })
    }

    const handleTextChange = (curFieldName: keyof NewSSOEntitiesAndGroupMaster) => (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "UPDATE_STRING_FIELD", fieldName: curFieldName, payload: event.target.value})
    }

    const handleDateRangeChange = (curFieldName: keyof NewSSOEntitiesAndGroupMaster) => (event: moment.Moment | null) => {

        dispatch({ type: "SET_DATE_VALUE", fieldName: curFieldName, payload: event })
    }

    const handleStateGroupChange = (event: SelectChangeEvent) => {
        if (event.target.value !== "") {
            const asNum = Number(event.target.value)
            if (asNum !== undefined && !isNaN(asNum)) {
                dispatch({type: "UPDATE_NUMBER_FIELD", fieldName: "parentGroupId", payload: asNum})
            }
        }
    }

    const handleSSOSystemChange = (event: SelectChangeEvent) => {
        console.log(`Value: ${event.target.value}`)
        console.log(`type of Value: ${typeof event.target.value}`)
        if (event.target.value) {
            dispatch({type: "UPDATE_STRING_FIELD", fieldName: "ssoSystem", payload: event.target.value})
        }
    }

    const handleNewProductPackageLicenseChangeAdd = useCallback((license: SSOLicenseCount) => {
        console.log(`Adding License: ${JSON.stringify(license)}`)
        dispatch({type: "ADD_LICENSE", payload: license})
    }, [])

    const handleNewProductPackageDelete = useCallback((license: string) => {
        dispatch({type: "REMOVE_LICENSE", payload: license})
    }, [])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { maxWidth: "800px", width: "100%" }  // Set custom width here
            }}
        >
            <DialogTitle sx={{marginLeft: 1, paddingBottom: 0}}>Add SSO Entity</DialogTitle>
            <DialogContent>
                <Box sx={{margin: 1}}>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <TextField
                                required={true}
                                margin={"dense"}
                                id={"new-sso-entity-name"}
                                label={"SSO Entity Name"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={handleTextChange("name")}
                                value={newSSOEntity.entity.name ? newSSOEntity.entity.name : undefined}
                                error={newSSOEntity.errors.nameError !== null}
                                helperText={newSSOEntity.errors.nameError}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                required={true}
                                sx={{paddingBottom: 1}}
                                margin={"dense"}
                                id={"new-sso-id"}
                                label={"SSO ID"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                value={newSSOEntity.entity.ssoId ? newSSOEntity.entity.ssoId : undefined}
                                onChange={handleTextChange("ssoId")}
                                error={newSSOEntity.errors.ssoIdError !== null}
                                helperText={newSSOEntity.errors.ssoIdError}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <DatePicker
                                label="Testing Starts"
                                value={newSSOEntity.entity.testingStart}
                                onChange={handleDateRangeChange("testingStart")}
                                sx={{width: "100%", paddingBottom: 1}}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        error: newSSOEntity.errors.testingStartError !== null,
                                        helperText: newSSOEntity.errors.testingStartError
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <DatePicker
                                label="Testing Ends"
                                value={newSSOEntity.entity.testingEnd}
                                onChange={handleDateRangeChange("testingEnd")}
                                sx={{width: "100%", paddingBottom: 1}}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        error: newSSOEntity.errors.testingEndError !== null,
                                        helperText: newSSOEntity.errors.testingEndError
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <DatePicker
                                label="Term Starts"
                                value={newSSOEntity.entity.termStart}
                                onChange={handleDateRangeChange("termStart")}
                                sx={{width: "100%", paddingBottom: 1}}
                                slotProps={{
                                    textField: {
                                        required: false,
                                        error: newSSOEntity.errors.termStartError !== null,
                                        helperText: newSSOEntity.errors.termStartError
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <DatePicker
                                label="Term Ends"
                                value={newSSOEntity.entity.termEnd}
                                onChange={handleDateRangeChange("termEnd")}
                                sx={{width: "100%", paddingBottom: 1}}
                                slotProps={{
                                    textField: {
                                        required: false,
                                        error: newSSOEntity.errors.termEndError !== null,
                                        helperText: newSSOEntity.errors.termEndError
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <FormControl fullWidth={true}>
                                <InputLabel id="sso-entity-state-select-label">SSO Parent State</InputLabel>
                                <Select
                                    labelId="sso-entity-state-select-label"
                                    id="sso-entity-state-select"
                                    label="SSO Parent State"
                                    value={newSSOEntity.entity.parentGroupId ? newSSOEntity.entity.parentGroupId.toString() : ""}
                                    onChange={handleStateGroupChange}
                                    required={true}
                                    error={newSSOEntity.errors.parentGroupIdError !== null}
                                >
                                    {
                                        listOfStates.map((state) => {
                                            return (
                                                <MenuItem key={state.groupId} value={state.groupId}>{state.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <FormControl fullWidth={true}>
                                <InputLabel id="sso-system-select-label">SSO System</InputLabel>
                                <Select
                                    labelId="sso-system-select-label"
                                    id="sso-system-select"
                                    label="SSO System"
                                    value={newSSOEntity.entity.ssoSystem ? newSSOEntity.entity.ssoSystem : ""}
                                    onChange={handleSSOSystemChange}
                                >
                                    {
                                        Object.keys(SSO_ENTITY_TYPES).map((ssoSystem) => {
                                            return (
                                                <MenuItem
                                                    key={ssoSystem}
                                                    value={SSO_ENTITY_TYPES[ssoSystem as keyof typeof SSO_ENTITY_TYPES]}
                                                >
                                                    {SSO_ENTITY_TYPES[ssoSystem as keyof typeof SSO_ENTITY_TYPES]}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={3}>
                            <TextField
                                required={true}
                                margin={"dense"}
                                id={"new-sso-entity-opp-number"}
                                label={"Opp Code"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={handleTextChange("oppNumber")}
                                value={newSSOEntity.entity.oppNumber ? newSSOEntity.entity.oppNumber : undefined}
                                error={newSSOEntity.errors.oppNumberError !== null}
                                helperText={newSSOEntity.errors.oppNumberError}
                            />
                        </Grid>
                        <Grid item={true} xs={3}>
                            <TextField
                                required={true}
                                margin={"dense"}
                                id={"new-sso-entity-oauth-key"}
                                label={"OAUTH Key"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={handleTextChange("oauth")}
                                value={newSSOEntity.entity.oauth ? newSSOEntity.entity.oauth : undefined}
                                error={newSSOEntity.errors.oauthError !== null}
                                helperText={newSSOEntity.errors.oauthError}
                            />
                        </Grid>
                        <Grid item={true} xs={3}>
                            <TextField
                                required={false}
                                margin={"dense"}
                                id={"new-sso-entity-app-id"}
                                label={"App ID"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={handleTextChange("appId")}
                                value={newSSOEntity.entity.appId ? newSSOEntity.entity.appId : undefined}
                                error={newSSOEntity.errors.appIdError !== null}
                                helperText={newSSOEntity.errors.appIdError}
                            />
                        </Grid>
                        <Grid item={true} xs={3}>
                            <TextField
                                required={false}
                                margin={"dense"}
                                id={"new-sso-entity-current-term"}
                                label={"Current Term"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={handleTextChange("currentTerm")}
                                value={newSSOEntity.entity.currentTerm ? newSSOEntity.entity.currentTerm : undefined}
                                error={newSSOEntity.errors.currentTermError !== null}
                                helperText={newSSOEntity.errors.currentTermError}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                required={false}
                                margin={"dense"}
                                id={"new-sso-entity-district-group"}
                                label={"District Group Name"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={handleTextChange("districtGroupName")}
                                value={newSSOEntity.entity.districtGroupName ? newSSOEntity.entity.districtGroupName : undefined}
                                error={newSSOEntity.errors.districtGroupNameError !== null}
                                helperText={newSSOEntity.errors.districtGroupNameError}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                required={false}
                                margin={"dense"}
                                id={"new-sso-entity-customer-group"}
                                label={"Customer Group Name"}
                                type={"text"}
                                fullWidth={true}
                                variant={"outlined"}
                                onChange={handleTextChange("customerGroupName")}
                                value={newSSOEntity.entity.customerGroupName ? newSSOEntity.entity.customerGroupName : undefined}
                                error={newSSOEntity.errors.customerGroupNameError !== null}
                                helperText={newSSOEntity.errors.customerGroupNameError}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <LicensesComponent
                                licensesSold={newSSOEntity.entity.licensesSold}
                                addNewProductPackageLicense={handleNewProductPackageLicenseChangeAdd}
                                removeProductPackageLicense={handleNewProductPackageDelete}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Button
                                id={"save-new-group-button"}
                                onClick={handleSaveNewEntity}
                                variant={"contained"}
                                fullWidth={true}
                                color={"primary"}
                                endIcon={<SaveIcon />}
                            >
                                SAVE GROUP
                            </Button>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Button
                                id={"cancel-add-new-group-button"}
                                onClick={handleClose}
                                variant={"contained"}
                                fullWidth={true}
                                color={"error"}
                                endIcon={<CancelIcon />}
                            >
                                CANCEL GROUP CREATION
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )

}

export default React.memo(SSOEntityAddNewEntityModal)
