/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {AxiosError, AxiosResponse} from "axios"
import {GroupType, ManageGroupsGroupMasterCronDTO} from "../../../../app/common/group/models/GroupMaster"
import ApiService from "../../../../services/ApiService"
import {ApiErrorResponse} from "../../../../types/types"
import {
    NewSSOEntitiesAndGroupMaster,
    SSOEntitiesAndGroupMastersResults,
    UpdateSSOEntityStateGroup,
} from "./components/SSOEntityTypes"

export default class  SSOEntityToolApi {
    static getSSOEntitiesFromApi(): Promise<Array<SSOEntitiesAndGroupMastersResults>> {
        const url: string = `${ApiService.API_URL}sso-entity-new/list`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse) => { resolve(response.data) },
                (error: ApiErrorResponse) => { reject(error) }
            )
        })
    }

    static getSSOEntityOauthKeyFromApi(entityId: string): Promise<string> {
        const url: string = `${ApiService.API_URL}sso-entity-new/get-oauth-key/${entityId}`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse) => { resolve(response.data) },
                (error: ApiErrorResponse) => { reject(error) }
            )
        })
    }

    static updateSSOEntityParentGroupMaster(updateBody: UpdateSSOEntityStateGroup): Promise<ManageGroupsGroupMasterCronDTO[]> {
        const url: string = `${ApiService.API_URL}sso-entity/update-state-group`

        return new Promise((resolve, reject) => {
            ApiService.post(url, updateBody).then(
                (response: AxiosResponse) => { resolve(response.data) },
                (err: ApiErrorResponse) => { reject(err) }
            )
        })
    }

    static getGroupMasterByTypeAndSsoId(groupType: GroupType, ssoId: string | null): Promise<ManageGroupsGroupMasterCronDTO[]> {
        const ssoIdQueryParam = ssoId ? `?ssoId=${ssoId}` : ""
        const url: string = `${ApiService.API_URL}groupmasters-new/groups-by-type/${groupType}${ssoIdQueryParam}`

        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (response: AxiosResponse<ManageGroupsGroupMasterCronDTO[]>) => {resolve(response.data)},
                (err: AxiosError) => {reject(err)}
            )
        })
    }

    static createNewSSOEntityWithGroups(newSSOEntity: NewSSOEntitiesAndGroupMaster): Promise<SSOEntitiesAndGroupMastersResults> {
        const url: string = `${ApiService.API_URL}sso-entity/create-new-sso-entity`
        return new Promise((resolve, reject) => {
            ApiService.post(url, newSSOEntity).then(
                (resp: AxiosResponse<SSOEntitiesAndGroupMastersResults>) => {resolve(resp.data)},
                (err: AxiosError) => {reject(err)}
            )
        })
    }

}