/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Button, TextField} from "@mui/material"
import {GridRenderCellParams} from "@mui/x-data-grid-pro"
import React, {useState} from "react"
import SSOEntityToolApi from "../SSOEntityToolApi"

const ButtonOrValueComponent: ({params}: { params: GridRenderCellParams }) => (React.JSX.Element) = ({params}: {params: GridRenderCellParams}) => {

    console.log("RENDER OAUTH BUTTON")

    const [value, setValue] = useState<string | null>(
        params.value ? params.value.toString() : null
    )
    const [loading, setLoading] = useState<Boolean>(false)
    const [editingRowId, setEditingRowId] = useState<string | number | null>(null)

    const showOauthKeyOnPress = (rowId: string | number) => {
        setLoading(true)
        SSOEntityToolApi.getSSOEntityOauthKeyFromApi(rowId.toString()).then(resp => {
            setValue(resp)
            setEditingRowId(rowId)
        }).catch(error => {
            console.error("Error retrieving data ", error)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleBlur = () => {
        setEditingRowId(null)
    }

    if (loading) {
        return <span>Loading...</span>
    }

    if (value !== null && params.id === editingRowId) {
        return (
            <TextField
                value={value}
                onChange={(e) => setValue(e.target.value)}
                variant={"filled"}
                // onBlur={() => params.api.setEditCellValue({id: params.id, field: params.field, value})}
                onBlur={handleBlur}
                fullWidth={true}
            />
        )
    }

    return (
        <Button
            variant={"contained"}
            onClick={() => showOauthKeyOnPress(params.id)}
            fullWidth={true}
        >
            Fetch Oauth Key
        </Button>
    )

}

export default ButtonOrValueComponent
