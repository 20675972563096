import {Moment} from "moment"
import {GroupType} from "../../../../../app/common/group/models/GroupMaster"
import {SSOLicenseCount} from "../../../../../types/types"

export interface SSOEntitiesAndGroupMastersResults {
    id: string,
    name: string,
    testingStart: Date,
    testingEnd: Date,
    termStart: Date | null,
    termEnd: Date | null,
    ssoSystem: string,
    oppNumber: string,
    ssoId: string,
    oauth: string | null,
    appId: string | null,
    licensesSold: SSOLicenseCount[],
    currentTerm: string[],
    parentGroupId: number | null,
    parentGroupName: string | null,
    parentGroupType: GroupType | null,
    districtGroupId: number | null,
    districtGroupName: string | null,
    districtGroupType: GroupType | null,
    customerGroupId: number | null,
    customerGroupName: string | null,
    customerGroupType: GroupType | null
}

export enum SSO_ENTITY_TYPES {
    CLEVER = "Clever",
    CLASS_LINK = "ClassLink",
    NYCDOE = "NYCDOE",
    SAML = "SAML"
}

export interface NewSSOEntitiesAndGroupMaster {
    name: string | null,            // cant be null or empty
    ssoId: string | null,           // cant be null or empty
    testingStart: Moment | null,    // cant be null or empty
    testingEnd: Moment | null,      // cant be null or empty
    termStart: Moment | null,
    termEnd: Moment | null,
    parentGroupId: number | null,   // cant be null or empty
    ssoSystem: string | null,       // cant be null or empty
    oppNumber: string | null,       // cant be null or empty
    oauth: string | null,           // cant be null or empty N/A for ones with no oauth key
    appId: string | null,
    currentTerm: string[] | null,
    districtGroupName: string | null,       // this or customer cant be empty or null
    districtGroupType: GroupType | null,    // Cant be null if district is not null
    customerGroupName: string | null,       // this or district cant be empty or null
    customerGroupType: GroupType | null     // Cant be null if customer is not null
    licensesSold: SSOLicenseCount[],        // Must have at least one valid license object
}

export interface NewSSOEntitiesAndGroupMasterErrors {
    nameError: string | null,
    testingStartError: string | null,
    testingEndError: string | null,
    termStartError: string | null,
    termEndError: string | null,
    ssoSystemError: string | null,
    oppNumberError: string | null,
    ssoIdError: string | null,
    oauthError: string | null,
    appIdError: string | null,
    licensesSoldError: string | null,
    currentTermError: string | null,
    parentGroupIdError: string | null,
    districtGroupNameError: string | null,
    districtGroupTypeError: string | null,
    customerGroupNameError: string | null,
    customerGroupTypeError: string | null
}


export interface UpdateSSOEntityStateGroup {
    ssoEntityId: string,
    groupId: number
}

export interface UpdateSSOEntityStateGroupResponse {
    ssoEntity: SSOEntitiesAndGroupMastersResults,
    success: boolean,
    message: string | null
}
