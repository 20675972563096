/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {Theme} from "@material-ui/core"
import createStyles from "@material-ui/core/styles/createStyles"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, {createContext, ReactElement, useContext, useState} from "react"
import moment from "moment"
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import {pickersLayoutClasses, PickersShortcutsItem, PickersShortcutsProps} from "@mui/x-date-pickers-pro"
import { DateRange } from '@mui/x-date-pickers-pro'
import {Moment} from "moment"
import {Box, Checkbox, Chip, FormControlLabel, List, ListItem} from "@mui/material"

const AvantDatePickerTextBoxStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateRangePickerStyles: {
            "& .MuiInputBase-root": {
                paddingTop: 0,
                paddingBottom: 0,
                "& .MuiButtonBase-root": {
                    paddingTop: 8.5,
                    paddingBottom: 8.5,
                },
                "& .MuiInputBase-input": {
                    paddingTop: 8.5,
                    paddingBottom: 8.5,
                }
            }
        }
    })
)

export interface DatePickerOptions {
    recentActivity: boolean,
    startDate: boolean,
    toggleOptions?: (option: "recentActivity" | "startDate") => void
}

const PickerOptionsContext = createContext<DatePickerOptions>({
    recentActivity: false,
    startDate: true,
})

const CustomRangeShortcuts: React.FC<PickersShortcutsProps<DateRange<Moment>>> = ({items, onChange, isValid}): ReactElement | null => {
    const {recentActivity, startDate, toggleOptions} = useContext(PickerOptionsContext)

    if (items == null || items.length === 0) {
        return null
    }

    const resolvedItems = items.map((item: PickersShortcutsItem<DateRange<Moment>>) => {
        const newValue = item.getValue({ isValid })

        return {
            label: item.label,
            // @ts-ignore
            onClick: () => onChange(newValue),
            disabled: !isValid(newValue),
        }
    })

    return (
        <Box>
            <List>
                {resolvedItems.map((item) => (
                    <ListItem key={item.label}>
                        <Chip {...item} />
                    </ListItem>
                ))}
                <ListItem>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={"Recent Activity"}
                        checked={recentActivity}
                        onChange={() => toggleOptions && toggleOptions("recentActivity")}
                    />
                </ListItem>
                <ListItem>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={"Start Date"}
                        checked={startDate}
                        onChange={() => toggleOptions && toggleOptions("startDate")}
                    />
                </ListItem>
            </List>
        </Box>
    )
}

interface AvantDatePickerTextBoxProps {
    dateRange: DateRange<Moment>
    setParentDateRange: (range: DateRange<Moment>, options: DatePickerOptions|null) => void
}

export const AvantControlledDateRangePicker: React.FC<AvantDatePickerTextBoxProps> = ({dateRange, setParentDateRange}) => {
    const classes = AvantDatePickerTextBoxStyles()
    const [pickerOptions, setPickerOptions] = useState<DatePickerOptions>({
        recentActivity: false,
        startDate: true,
    })

    const toggleOptions = (option: "recentActivity" | "startDate") => {
        const newOptions = {...pickerOptions}
        newOptions[option] = !pickerOptions[option]

        setPickerOptions(newOptions)
    }

    const dateChange = (dateRange: DateRange<Moment>) => {
        setParentDateRange(dateRange, pickerOptions)
    }

    const dateRangeShortCuts: PickersShortcutsItem<DateRange<Moment>>[] = [
        { label: 'Last 3 Months', getValue: () => [moment().subtract(3, "months"), moment()] },
        { label: 'Last 6 Months', getValue: () => [moment().subtract(6, "months"), moment()] },
        { label: 'Last Year', getValue: () => [moment().subtract(12, "months"), moment()] },
        { label: 'Reset', getValue: () => [null, null] }
    ]

    return (
        <PickerOptionsContext.Provider value={{toggleOptions, ...pickerOptions}}>
            <>
                <DateRangePicker
                    className={classes.dateRangePickerStyles}
                    sx={{minWidth: 225}}
                    slots={{
                        field: SingleInputDateRangeField,
                        shortcuts: CustomRangeShortcuts,
                    }}
                    value={dateRange}
                    slotProps={{
                        layout: {
                            sx: {
                                [`.${pickersLayoutClasses.contentWrapper}`]: {
                                    gridColumn: 2,
                                    gridRow: 1,
                                },
                            },
                        },
                        shortcuts: {
                            items: dateRangeShortCuts
                        },
                        actionBar: {
                            actions: []
                        }
                    }}
                    onChange={(newValue) => dateChange(newValue)}
                />
            </>
        </PickerOptionsContext.Provider>
    )
}
