/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import {AxiosResponse} from "axios"
import {ApiErrorResponse} from "../types/types"
import ApiService from "./ApiService"

export interface StateForList {
    groupId: string,
    stateName: string,
}

export interface Group {
    groupId: string,
    name: string
}

export default class GroupApi {
    static getAllStatesForDropdown(): Promise<StateForList[]> {
        const url: string = `${ApiService.API_URL}groupmasters/states`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }

    static getAllChildGroupsForDropDown(groupId: number): Promise<Group[]> {
        const url: string = `${ApiService.API_URL}groupmasters/group/${groupId}`
        return new Promise((resolve, reject) => {
            ApiService.get(url).then(
                (res: AxiosResponse) => {
                    resolve(res.data)
                },
                (err: ApiErrorResponse) => {
                    reject(err)
                }
            )
        })
    }
}

