/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import {Button} from "@mui/material"
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton
} from "@mui/x-data-grid-pro"
import React from "react"

interface SSOEntityCustomToolBarProps {
    onAddEntity: () => void
}

const SSOEntityCustomToolBar: React.FC<SSOEntityCustomToolBarProps> = (props) => {

    console.log("RENDERED TOOLBAR")

    const { onAddEntity } = props

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport />
            <Button
                onClick={onAddEntity}
                // variant={""}
            >
                Add SSO Entity
            </Button>
        </GridToolbarContainer>
    )

}

export default React.memo(SSOEntityCustomToolBar)
