/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import CancelIcon from "@mui/icons-material/Cancel"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import {Box, MenuItem, Select} from "@mui/material"
import {
    DataGridPro,
    DataGridProProps, GridActionsCellItem,
    GridCellEditStartParams,
    GridColDef,
    GridEventListener,
    GridRenderCellParams,
    GridRowEditStopReasons,
    GridRowId, GridRowModel,
    GridRowModes,
    GridRowModesModel, GridSlots
} from "@mui/x-data-grid-pro"
import React, {useCallback, useEffect, useMemo, useState} from "react"
import GroupApi, {StateForList} from "../../../../../services/GroupApi"
import {log} from "../../../../../util/Logging"
import ButtonOrValueComponent from "../components/ButtonOrValueComponent"
import LicensesSoldDetailPanel from "../components/LicensesSoldDetailPanel"
import SSOEntityAddNewEntityModal from "../components/SSOEntityAddNewEntityModal"
import SSOEntityCustomToolBar from "../components/SSOEntityCustomToolBar"
import {SSOEntitiesAndGroupMastersResults} from "../components/SSOEntityTypes"
import SSOEntityToolApi from "../SSOEntityToolApi"

interface SSOEntityToolIState {
    entityList: SSOEntitiesAndGroupMastersResults[],
}

const SSOEntityTool: React.FC<SSOEntityToolIState> = () => {

    const [ssoEntityToolState, setSsoEntityToolState] = useState<SSOEntityToolIState>({
        entityList: []
    })

    // TODO: Complete update row implementation
    // @ts-ignore
    const [currentEditRowId, setCurrentEditRowId] = useState<string | number | null>(null)

    const [stateGroupsList, setStateGroupsList] = useState<StateForList[]>([])
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false)


    const getSSOEntityDataFromApi = useCallback(() => {
        SSOEntityToolApi.getSSOEntitiesFromApi().then(resp => {
            if (resp.length > 0) {
                setSsoEntityToolState((prevState) => ({
                    ...prevState,
                    entityList: resp
                }))
            }
        })
    }, [])


    const getStateGroupMastersFromApi = useCallback(() => {
        GroupApi.getAllStatesForDropdown().then((resp: StateForList[]) => {
            setStateGroupsList(resp)
        })
    }, [])

    useEffect(() => {
        getSSOEntityDataFromApi()
        getStateGroupMastersFromApi()
    }, [getSSOEntityDataFromApi, getStateGroupMastersFromApi])

    // @ts-ignore
    // const changeParentGroup = (params: GridRenderCellParams, event: SelectChangeEvent<string>) => {
    //     const newValue = event.target.value
    //     const newValueNumber: number = parseInt(newValue)
    //     const stringId: string = params.id.toString()
    //
    //     // TODO: Call API with Update:
    //     SSOEntityToolApi.updateSSOEntityParentGroupMaster({ssoEntityId: stringId, groupId: newValueNumber}).then(resp =>
    //         console.log(JSON.stringify(resp))
    //     )
    //
    //     params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event)
    // }

    // const handleProcessRowUpdate = (newRow: GridRowModel) => {
    //     console.log("HANDLE UPDATE ROW")
    //     const updatedRow = { ...newRow }
    //     console.log(`NewRow: ${JSON.stringify(updatedRow)}`)
    //     // setSsoEntityToolState(ssoEntityToolState.entityList.map((row) => row.id === newRow.id ? updatedRow : row))
    //     return newRow
    // }

    const handleProcessRowUpdate = useCallback((newRow: GridRowModel) => {
        const updatedRow = { ...newRow }
        // TODO: Implement Update row handler
        log.debug(`NewRow: ${JSON.stringify(updatedRow)}`)
        // setSsoEntityToolState(ssoEntityToolState.entityList.map((row) => row.id === newRow.id ? updatedRow : row))
        return newRow
    }, [])


    const handleRowEditStop: GridEventListener<"rowEditStop"> = useCallback((params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true
        }
    }, [])

    const handleRowUpdateError = useCallback(() => {
        // TODO: Implement Update Error handler
        log.debug("ERROR UPDATING ROW")
    }, [])

    const handleEditClick = useCallback((id: GridRowId) => () => {
        // Set the Row in edit mode for the currently selected Row
        setRowModesModel({ [id]: { mode: GridRowModes.Edit } })
    }, [])

    const handleSaveClick = useCallback((id: GridRowId) => () => {
        // Set the Row in view mode for the currently selected Row
        setRowModesModel({ [id]: { mode: GridRowModes.View } })
    }, [])

    const handleCancelClick = useCallback((id: GridRowId) => () => {
        setRowModesModel({
            [id]: { mode: GridRowModes.View, ignoreModifications: true }
        })
    }, [])

    const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }, [])

    const handleCellEditStart = useCallback((params: GridCellEditStartParams) => {
        setCurrentEditRowId(params.id)
    }, [])

    const handelCellEditStop = useCallback(() => {
        setCurrentEditRowId(null)
    }, [])

    const getLicensesSoldDetailContent = useCallback<NonNullable<DataGridProProps["getDetailPanelContent"]>>(
        ({ row }) => <LicensesSoldDetailPanel row={row} />
    , [])

    const getLicensesSoldDetailHeight = useCallback<NonNullable<DataGridProProps["getDetailPanelHeight"]>>(() => "auto" as const, [])

    const handleAddEntityClick = useCallback(() => {
        setModalOpen(true)
    }, [])

    const handleModalClose = useCallback(() => {
        setModalOpen(false)
    }, [])

    const handleSaveNewEntity = useCallback((newEntity: SSOEntitiesAndGroupMastersResults) => {
        // console.log("SAVE NEW ENTITY CLICKED!!!")
        const newArray = [newEntity, ...ssoEntityToolState.entityList]
        setSsoEntityToolState({...ssoEntityToolState, entityList: newArray})
    }, [])

    const columns: GridColDef[] = useMemo(() =>  [
        { field: "actions", type: "actions", headerName: "Actions", width: 100, cellClassName: "actions",
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
                if (isInEditMode) {
                    return [
                        (
                            <GridActionsCellItem
                                key={`save-${id}`}
                                label={"Save"}
                                icon={<SaveIcon />}
                                sx={{color: "primary.main"}}
                                onClick={handleSaveClick(id)}
                            />
                        ), (
                            <GridActionsCellItem
                                key={`cancel-${id}`}
                                label={"Cancel"}
                                className={"textPrimary"}
                                icon={<CancelIcon />}
                                sx={{color: "inherit"}}
                                onClick={handleCancelClick(id)}
                            />
                        ),
                    ]
                }
                return [
                    (
                        <GridActionsCellItem
                            label={"Edit"}
                            icon={<EditIcon />}
                            className={"textPrimary"}
                            sx={{color: "inherit"}}
                            onClick={handleEditClick(id)}
                        />
                    )
                ]
            }
        },
        { field: "id", headerName: "SSO Entity Id", type: "string", editable: false, width: 260, hideable: true, headerAlign: "center"},
        { field: "name", headerName: "SSO Entity Name", type: "string", editable: true, width: 250, hideable: true, headerAlign: "center" },
        { field: "testingStart", headerName: "Testing Starts", type: "date", editable: true, width: 100, hideable: true,
            valueGetter: (value) => new Date(value), headerAlign: "center", align: "center" },
        { field: "testingEnd", headerName: "Testing Ends", type: "date", editable: true, width: 100, hideable: true,
            valueGetter: (value) => new Date(value), headerAlign: "center", align: "center" },
        { field: "termStart", headerName: "Term Start", type: "date", editable: true, width: 100, hideable: true,
            valueGetter: (value) => new Date(value), headerAlign: "center", align: "center" },
        { field: "termEnd", headerName: "Term End", type: "date", editable: true, width: 100,  hideable: true,
            valueGetter: (value) => new Date(value), headerAlign: "center", align: "center" },
        { field: "currentTerm", headerName: "Current Term", type: "string", editable: false, width: 200, hideable: true,
            headerAlign: "center", align: "center" },
        { field: "ssoSystem", headerName: "SSO System", type: "string", editable: false, width: 120, hideable: true, headerAlign: "center", align: "center" },
        { field: "oppNumber", headerName: "Opp Code", type: "string", editable: false, width: 120, hideable: true, headerAlign: "center", align: "center" },
        { field: "ssoId", headerName: "SSO ID", type: "string", editable: false, width: 200, hideable: true, headerAlign: "center", align: "center" },
        { field: "oauth", headerName: "OAUTH Key", editable: true, width: 260, hideable: true, headerAlign: "center", align: "center", disableExport: true,
            renderCell: (params: GridRenderCellParams) => <ButtonOrValueComponent params={params} />
        },
        { field: "licensesSold", headerName: "Licenses", type: "string", editable: false, width: 200, hideable: true, headerAlign: "center", align: "center",
            valueGetter: (value: GridRenderCellParams) => {
                return JSON.stringify(value)
            }
        },
        { field: "appId", headerName: "App Id (Class Link)", type: "string", editable: false, width: 200,
            hideable: true, headerAlign: "center", align: "center" },
        { field: "parentGroupName", headerName: "Parent Group", type: "string", editable: true, width: 150, hideable: true, headerAlign: "center", align: "center",
            renderCell: (params: GridRenderCellParams) => {
                const groupType = params.row.parentGroupType ? params.row.parentGroupType : "NO TYPE"
                // const groupName = params.row.parentGroupName ? params.row.parentGroupName.replace(/ /g, "▪") : "NO NAME"
                const groupName = params.row.parentGroupName ? params.row.parentGroupName : "NO NAME"
                return `${groupType} - ${groupName}`
            },
            renderEditCell: (params: GridRenderCellParams) => {
                // console.log(`Group ID: ${params.row.parentGroupId}`)
                // console.log(`Params: ${JSON.stringify(params.row)}`)
                const rowId = params.row.parentGroupId ? params.row.parentGroupId : ""
                return (
                    <Select
                        value={rowId}
                        // onChange={(event: SelectChangeEvent<string>) => changeParentGroup(params, event)}
                        onChange={(event) => params.api.setEditCellValue({ id: params.id, field: "parentGroupId", value: event.target.value })}
                        autoFocus={true}
                    >
                        {
                            stateGroupsList.map((stateGroup) => {
                                return (
                                    <MenuItem key={stateGroup.groupId} value={stateGroup.groupId}>
                                        {stateGroup.stateName}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                )
            }
        },
        { field: "districtGroupName", headerName: "District Group", type: "string", editable: true, width: 150, hideable: true, headerAlign: "center", align: "center",
            renderCell: (params: GridRenderCellParams) => {
                const isNullName = params.row.districtGroupName ? "" : "No District Group"
                const groupType = params.row.districtGroupType ? params.row.districtGroupType : isNullName
                // const groupName = params.row.districtGroupName ? params.row.districtGroupName.replace(/ /g, "▪") : isNullName
                const groupName = params.row.districtGroupName ? params.row.districtGroupName : isNullName
                return `${groupType} - ${groupName}`
            }
        },
        { field: "customerGroupName", headerName: "Customer Group", type: "string", editable: true, width: 150, hideable: true, headerAlign: "center", align: "center",
            renderCell: (params: GridRenderCellParams) => {
                const isNullName = params.row.customerGroupType ? "" : "No Customer Group"
                const groupType = params.row.customerGroupType ? params.row.customerGroupType : isNullName
                // const groupName = params.row.customerGroupName ? params.row.customerGroupName.replace(/ /g, "▪") : isNullName
                const groupName = params.row.customerGroupName ? params.row.customerGroupName : isNullName
                return `${groupType} - ${groupName}`
            }
        }
    ], [rowModesModel, stateGroupsList, handleSaveClick, handleCancelClick, handleEditClick])

    return(
        <Box sx={{ width: "100%"}}>
            <DataGridPro
                autoHeight={true}
                columns={columns}
                rows={ssoEntityToolState.entityList}
                disableRowSelectionOnClick={true}
                onCellEditStart={handleCellEditStart}
                onCellEditStop={handelCellEditStop}
                processRowUpdate={handleProcessRowUpdate}
                pagination={true}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                getDetailPanelHeight={getLicensesSoldDetailHeight}
                getDetailPanelContent={getLicensesSoldDetailContent}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                onProcessRowUpdateError={handleRowUpdateError}
                // The below ts-ignore is needed because of memoization, will report to MUI-X devs
                // @ts-ignore
                slots={{ toolbar: SSOEntityCustomToolBar as GridSlots["toolbar"] }}
                slotProps={{ toolbar: {onAddEntity: handleAddEntityClick} }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            page: 0,
                            pageSize: 10
                        }
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            oauth: false,
                            termStart: false,
                            termEnd: false,
                            currentTerm: false,
                            appId: false
                        }
                    }
                }}
            />
            <SSOEntityAddNewEntityModal open={modalOpen} onClose={handleModalClose} onSave={handleSaveNewEntity} />
        </Box>
    )

}

export default React.memo(SSOEntityTool)
