/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import {Button, Paper} from "@mui/material"
import {Stack} from "@mui/system"
import {DataGridPro, GridColDef, useGridApiContext} from "@mui/x-data-grid-pro"
import React from "react"
import {SSOEntitiesAndGroupMastersResults} from "./SSOEntityTypes"

export default function LicensesSoldDetailPanel({row: rowProp}: {row: SSOEntitiesAndGroupMastersResults}) {
    const apiRef = useGridApiContext()

    const columns = React.useMemo<GridColDef<SSOEntitiesAndGroupMastersResults["licensesSold"][number]>[]>(
        () => [
            { field: "productPackage", headerName: "Product Package", type: "string"},
            { field: "count", headerName: "Count", type: "number"},
            { field: "price", headerName: "Price", type: "number"},
        ],
[],
    )

    const addProduct = React.useCallback(() => {
        console.log("Add New License")
    }, [apiRef, rowProp])

    return (
        <Stack sx={{ py: 2, height: 1, boxSizing: "border-box" }} direction={"column"}>
            <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
                <Stack direction={"column"} spacing={1} sx={{height: 1}}>
                    <div>
                        <Button variant="outlined" size="small" onClick={addProduct}>
                            Add Product
                        </Button>
                    </div>
                    <DataGridPro
                        density="compact"
                        autoHeight={true}
                        columns={columns}
                        rows={rowProp.licensesSold}
                        getRowId={(row) => row.productPackage + '-' + row.count + '-' + row.price}
                        sx={{flex: 1}}
                        hideFooter
                    />
                </Stack>
            </Paper>
        </Stack>
    )

}
