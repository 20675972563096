import {Checkbox, FormControlLabel, FormGroup, FormHelperText, InputLabel, FormLabel, OutlinedInput} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import moment from "moment-timezone/moment-timezone-utils"
import React from "react"
import ApiService from "../../../services/ApiService"
import {H2Text, H3Text} from "../../../styles/AvantTypography"
import {messageStore} from "../../common/messages/MessageStore"
import {P360ScheduleSlot} from "./P360ScheduleSlot"
import {DateTimePicker} from "@mui/x-date-pickers-pro"
import {Moment} from "moment"

const useP360ScheduleCreationStyle = makeStyles(theme => ({
    root: {
        marginTop: 0,
    },
    form_root: {
        display: "flex",
        flexWrap: "wrap",
        marginRight: "-15px",
        marginLeft: "-15px",
    },
    margin: {
        margin: theme.spacing(1),
    },
    dateMargins: {
        marginRight: theme.spacing(2)
    },
    buttonDivStyles: {
        textAlign: "center",
        margin: theme.spacing(1),
    },
    errorText: {
        color: "red"
    },
    daySelectErrorText: {
        color: "red",
        margin: "15px auto 5px auto",
    }
}))

interface P360ScheduleSlot {
    startTime: string | undefined
    endTime: string | undefined
    seats: number
}

interface DaysScheduleActive {
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean
}

export interface P360ScheduleData {
    p360SchedulingId: string | null
    title: string | undefined,
    description: string | undefined,
    scheduleType: string,
    repeatStartDate: string | Date | undefined
    repeatEndDate: string | Date | undefined
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
    sunday: boolean
    slots: P360ScheduleSlot[]
}

// This is what comes back from the API that was pulled from the DB
export interface P360AvantSchedule {
    id: string
    p360ScheduleRemoteId: number
    title: string
    scheduleStartDate: Date
    scheduleEndDate: Date
    scheduleType: string
    totalSeats: number | null | undefined
    daysScheduleActive: DaysScheduleActive
    slots: P360AvantScheduleSlot[]
    createDate: Date
    lasttouched: Date
    googleCalendarUpdated: Date
    googleCalendarEventId: string
}

// This is what comes back from the API that was pulled from the DB
interface P360AvantScheduleSlot {
    id: number
    exam_calendar_id: number
    start_time: string
    end_time: string
    seats: number | null | undefined
    available_seats: number | null | undefined
    time_zone_id: number | null | undefined
    created_at: string
    updated_at: string
}

export interface AvantP360ScheduleCreationResponse {
    successfulSchedule: P360AvantSchedule | undefined | null,
    unsuccessfulScheduleData: P360ScheduleData | undefined | null
    error: boolean
    listOfErrors: string[]
}

const getBlankP360ScheduleData = (): P360ScheduleData => {
    const localStartOfDay = moment().local().startOf("day").toString()
    const localEndOfDay = moment().local().endOf("day").toString()

    return {
        p360SchedulingId: null,
        title: "",
        description: "",
        scheduleType:  "daily",
        repeatStartDate:  new Date(localStartOfDay).toISOString(),
        repeatEndDate:  new Date(localEndOfDay).toISOString(),
        monday:  false,
        tuesday:  false,
        wednesday:  false,
        thursday:  false,
        friday:  false,
        saturday:  false,
        sunday:  false,
        slots:  []
    }
}

const fillInP360ScheduleDataFromAvantScheduleAndLocalSlots = (p360AvantSchedule: P360AvantSchedule | undefined | null, localSlots: P360ScheduleSlot[]): P360ScheduleData => {
    // Initialize with a blank P360ScheduleData.
    const p360ScheduleData: P360ScheduleData = getBlankP360ScheduleData()

    // If we have an empty p360AvantSchedule...
    if (typeof(p360AvantSchedule) === "undefined" || p360AvantSchedule === null) {
        // Early return with the blank P360ScheduleData.
        return p360ScheduleData
    }

    // Fill in p360SchedulingId.
    if (p360AvantSchedule.hasOwnProperty("id") && p360AvantSchedule.id !== null) {
        p360ScheduleData.p360SchedulingId = p360AvantSchedule.id
    }

    // Fill in title.
    if (p360AvantSchedule.hasOwnProperty("title") && p360AvantSchedule.title !== "") {
        p360ScheduleData.title = p360AvantSchedule.title
    }

    // Fill in repeatStartDate
    if (p360AvantSchedule.hasOwnProperty("scheduleStartDate")) {
        p360ScheduleData.repeatStartDate = p360AvantSchedule.scheduleStartDate
    }

    // Fill in repeatEndDate
    if (p360AvantSchedule.hasOwnProperty("scheduleEndDate")) {
        p360ScheduleData.repeatEndDate = p360AvantSchedule.scheduleEndDate
    }

    // Fill in the days of the week.
    // tslint:disable-next-line:max-line-length
    if (p360AvantSchedule.hasOwnProperty("daysScheduleActive") && typeof(p360AvantSchedule.daysScheduleActive) !== "undefined" && p360AvantSchedule.daysScheduleActive !== null) {
        Object.keys(p360AvantSchedule.daysScheduleActive).map(dayName => {
            if (p360ScheduleData.hasOwnProperty(dayName)) {
                p360ScheduleData[dayName] = Boolean(p360AvantSchedule.daysScheduleActive[dayName])
            }
        })
    }

    // Fill in the schedule slots.
    p360ScheduleData.slots = localSlots

    return p360ScheduleData
}

const getBlankP360ScheduleSlot = (): P360ScheduleSlot => {
    return {
        startTime: "",
        endTime: "",
        seats: 0
    }
}

const fillInP360ScheduleSlotsFromAvantSchedule = (p360AvantSchedule: P360AvantSchedule | undefined | null): P360ScheduleSlot[] => {
    // Initialize with an empty array of P360ScheduleSlots.
    const p360ScheduleSlots: P360ScheduleSlot[] = []

    // If we have an empty p360AvantSchedule...
    if (typeof(p360AvantSchedule) === "undefined" || p360AvantSchedule === null) {
        // Add a single blank slot.
        p360ScheduleSlots.push(getBlankP360ScheduleSlot())
        // Early return with the single blank slot.
        return p360ScheduleSlots
    }

    // Get the slots from p360AvantSchedule.
    if (p360AvantSchedule.hasOwnProperty("slots")) {
        p360AvantSchedule.slots.map((existingSlot) => {
            const newSlot: P360ScheduleSlot = getBlankP360ScheduleSlot()
            newSlot.startTime = existingSlot.start_time
            newSlot.endTime = existingSlot.end_time
            newSlot.seats = (typeof(existingSlot.seats) === "undefined" || existingSlot.seats === null) ? 0 : existingSlot.seats
            p360ScheduleSlots.push(newSlot)
        })
    }

    if (p360ScheduleSlots.length < 1) {
        // Add a single blank slot.
        p360ScheduleSlots.push(getBlankP360ScheduleSlot())
    }

    return p360ScheduleSlots
}

interface P360CreateScheduleProps {
    existingScheduleData: P360AvantSchedule | undefined | null     
    modalClose: () => void 
}
export const P360CreateSchedule: React.FC<P360CreateScheduleProps> = ({existingScheduleData,modalClose}) => {

    const classes = useP360ScheduleCreationStyle()

    const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const [localSlots, setLocalSlots] = React.useState<P360ScheduleSlot[]>(fillInP360ScheduleSlotsFromAvantSchedule(existingScheduleData))

    const [fieldErrors, setFieldErrors] = React.useState<string[]>([])

    const [scheduleData, setScheduleData] = React.useState <P360ScheduleData>(fillInP360ScheduleDataFromAvantScheduleAndLocalSlots(existingScheduleData, localSlots))

    const addSlot = () => {
        const curSlots = [...localSlots]
        const newSlot = getBlankP360ScheduleSlot()

        curSlots.push(newSlot)
        setLocalSlots(curSlots)
        setScheduleData({...scheduleData, slots: curSlots})
    }

    /**
     * Converts the Eugene local times entered by the admin into UTC time for passage to the API.
     * Note: The times stored as "standard" times are converted to local DST when necessary by the API when the slot
     * times are built for display on the test taker's scheduling page, so we do not consider DST here.
     * @param {string} time - The time to be converted.
     */
    const eugeneStandardTimeToUtc = (time: string) => {
        if (time === '') {
            // Early return:
            return ''
        }

        // The Z represents the time zone offset, like this: 08:00+00:00
        const timeFormatWithTimeZoneOffset = "HH:mmZ"
        const pstAsOffset = '-08:00'
        const timeWithTimeZoneOffset = time+pstAsOffset

        // Convert the PST time to UTC:
        return moment(timeWithTimeZoneOffset, timeFormatWithTimeZoneOffset).utc().format(timeFormatWithTimeZoneOffset)
    }

    const updateSlot = (slotIndex: number, field: string, value: any) => {
        const curSlots = [...localSlots]
        switch (field) {
            case ("start-time"): {
                if(fieldErrors.includes("invalid-slots")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("invalid-slots")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                const utcTime = eugeneStandardTimeToUtc(value)
                curSlots[slotIndex].startTime = utcTime
                break
            }
            case ("end-time"): {
                if(fieldErrors.includes("invalid-slots")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("invalid-slots")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                const utcTime = eugeneStandardTimeToUtc(value)
                curSlots[slotIndex].endTime = utcTime
                break
            }
            case ("seats"): {
                if(fieldErrors.includes("invalid-slots")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("invalid-slots")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                curSlots[slotIndex].seats = value
                break
            }
        }

        setLocalSlots(curSlots)
        setScheduleData({...scheduleData, slots: curSlots})
    }

    const handleSubmit = () => {
        ApiService.creatAvantP360Schedule(scheduleData).then(
            (res: AvantP360ScheduleCreationResponse) => {
                // Resolving the promise from API service wrapper call
                if (res.listOfErrors.length > 0) {
                    setFieldErrors(res.listOfErrors)
                    messageStore.setErrorMessage("Schedules must contain a valid title, start date, end date, day selection, and time slot selection.")
                } else {
                    const schedule = getBlankP360ScheduleData()
                    setLocalSlots([
                        {
                            startTime: "",
                            endTime: "",
                            seats: 0
                        }
                    ])
                    setScheduleData(schedule)
                    messageStore.setInfoMessage("Schedule saved!")
                        modalClose()
                }

            }
        )

    }

    const deleteSlot = (index: number) => {
        if(fieldErrors.includes("invalid-slots")){
            const err = [...fieldErrors]
            const errIndex = err.indexOf("invalid-slots")
            err.splice(errIndex, 1)
            setFieldErrors(err)
        }
        const currentSlots = [...localSlots]
        currentSlots.splice(index, 1)
        setLocalSlots(currentSlots)
        setScheduleData({...scheduleData, slots: currentSlots})
    }

    const generateSlots = () => {
        const moreThanOneSlot = localSlots.length > 1
        return localSlots.map((data, index) => {
            return (
                    <Grid item={true} xs={12} key={`schedule-slot-index-${index}`}>
                        <P360ScheduleSlot
                            updateParent={updateSlot}
                            startTime={data.startTime}
                            endTime={data.endTime}
                            seats={data.seats}
                            indexKey={index}
                            addRemoveSlotIcon={moreThanOneSlot}
                            deleteSlot={deleteSlot}
                            slotError={fieldErrors.includes("invalid-slots")}
                        />
                    </Grid>
                )
            })
    }


    /**
     * Converts the Eugene local date entered by the admin into UTC epoch time for passage to date constructor.
     * @param {string} time - The time to be converted.
     */
    const eugeneDateToUtcEpoch = (dateTime: string) => {
        if (dateTime == null) {
            // Early return:
            return ''
        }

        // Convert the PST time to UTC:
        return moment.tz(dateTime, 'America/Los_Angeles').utc().valueOf()
    }

    // This method handles the date picker components
    const handleDateChange = (date: Moment, value: string) => {
        const eugeneDate = date == null ? '' : date.format('YYYY-MM-DD HH:mm')
        const momentToUtcDateTime = new Date(eugeneDateToUtcEpoch(eugeneDate))

        switch (value) {
            case ("schedule-start-date"): {
                if(fieldErrors.includes("invalid-date")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("invalid-date")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, repeatStartDate: momentToUtcDateTime})
                break
            }
            case ("schedule-end-date"): {
                if(fieldErrors.includes("invalid-date")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("invalid-date")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, repeatEndDate: momentToUtcDateTime})
                break
            }
        }
    }

    /**
     * Converts the UTC date provided by calls to the API into Eugene time (standard or daylight saving,
     * depending on the date) for display to the admin.
     * @param {string | Date | undefined} dateTime - The date/time to be converted.
     */
    const eugeneDatefromUtc = (dateTime: string | Date | undefined) => {
        const eugeneDate = moment.utc(dateTime).tz('America/Los_Angeles')

        return eugeneDate == null ? "" : eugeneDate
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {

        switch (event.target.id) {

            case ("title"): {
                if(fieldErrors.includes("schedule-title-exists")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("schedule-title-exists")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                if(fieldErrors.includes("empty-title")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-title")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, title: event.target.value})
                break
            }
            case ("description"): {
                if(fieldErrors.includes("empty-description")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-description")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, description: event.target.value})
                break
            }
            case ("schedule-type"): {
                // TODO: Implement when we allow a different schedule type
                break
            }
            case (daysOfTheWeek[0]): {
                if(fieldErrors.includes("empty-days")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-days")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, monday: event.target.checked})
                break
            }
            case (daysOfTheWeek[1]): {
                if(fieldErrors.includes("empty-days")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-days")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, tuesday: event.target.checked})
                break
            }
            case (daysOfTheWeek[2]): {
                if(fieldErrors.includes("empty-days")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-days")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, wednesday: event.target.checked})
                break
            }
            case (daysOfTheWeek[3]): {
                if(fieldErrors.includes("empty-days")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-days")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, thursday: event.target.checked})
                break
            }
            case (daysOfTheWeek[4]): {
                if(fieldErrors.includes("empty-days")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-days")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, friday: event.target.checked})
                break
            }
            case (daysOfTheWeek[5]): {
                if(fieldErrors.includes("empty-days")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-days")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, saturday: event.target.checked})
                break
            }
            case (daysOfTheWeek[6]): {
                if(fieldErrors.includes("empty-days")){
                    const err = [...fieldErrors]
                    const errIndex = err.indexOf("empty-days")
                    err.splice(errIndex, 1)
                    setFieldErrors(err)
                }
                setScheduleData({...scheduleData, sunday: event.target.checked})
                break
            }
        }
    }

    return (
        <div className={classes.root}>
            <H2Text>P360 Schedule Create\Edit</H2Text>
            <H3Text>{`All times in Eugene, OR, time`}</H3Text>
            <div className={classes.form_root}>
                <FormControl
                    fullWidth={true}
                    className={classes.margin}
                    variant={"outlined"}
                    error={fieldErrors.includes("schedule-title-exists") || fieldErrors.includes("empty-title")}
                    required={true}
                >
                    <InputLabel htmlFor="outlined-title">Title</InputLabel>
                    <OutlinedInput
                        id="title"
                        labelWidth={30}
                        onChange={changeHandler}
                        value={scheduleData.title}
                    />
                    {
                        fieldErrors.includes("schedule-title-exists") &&
                        <FormHelperText className={classes.errorText}>
                            Title already exists in Database
                        </FormHelperText>
                    }
                    {
                        fieldErrors.includes("empty-title") &&
                        <FormHelperText className={classes.errorText}>
                            Please Enter Title
                        </FormHelperText>
                    }
                </FormControl>
                <FormControl
                    fullWidth={true}
                    className={classes.margin}
                    variant={"outlined"}
                    error={fieldErrors.includes("empty-description")}
                    /*required={true}*/
                    hidden={true}
                >
                    <InputLabel htmlFor="outlined-description">Description</InputLabel>
                    <OutlinedInput
                        id="description"
                        labelWidth={70}
                        onChange={changeHandler}
                        value={scheduleData.description}
                    />
                    {
                        fieldErrors.includes("empty-description") &&
                        <FormHelperText className={classes.errorText}>
                            Please Enter Description
                        </FormHelperText>
                    }
                </FormControl>
                <FormControl
                    fullWidth={true}
                    className={classes.margin}
                    variant={"outlined"}
                    required={true}
                    hidden={true}
                >
                    <InputLabel htmlFor="outlined-schedule-type">Schedule Type</InputLabel>
                    <OutlinedInput
                        id="schedule-type"
                        labelWidth={95}
                        defaultValue={"Repeat"}
                        disabled={true}
                        // onChange={changeHandler}
                    />
                </FormControl>
                <Grid
                    container={true}
                >
                    <Grid item={true} xs={6}>
                        <FormControl
                            className={classes.margin}
                            fullWidth={true}
                            variant={"outlined"}
                        >
                            {/*<KeyboardDateTimePicker*/}
                            {/*    error={fieldErrors.includes("invalid-date")}*/}
                            {/*    className={classes.dateMargins}*/}
                            {/*    disableToolbar={false}*/}
                            {/*    inputVariant={"outlined"}*/}
                            {/*    variant="inline"*/}
                            {/*    // The 'a' shows am/pm and the 'Z; after UTC gives the timezone offset*/}
                            {/*    format="MM/DD/YYYY - hh:mm a"*/}
                            {/*    margin="normal"*/}
                            {/*    id="start-date"*/}
                            {/*    label="Repeat Start Date/Time"*/}
                            {/*    value={eugeneDatefromUtc(scheduleData.repeatStartDate)}*/}
                            {/*    onChange={(date) => handleDateChange(date, "schedule-start-date")}*/}
                            {/*    KeyboardButtonProps={{*/}
                            {/*        "aria-label": "change date",*/}
                            {/*    }}*/}
                            {/*/>*/}
                                <DateTimePicker
                                    // id={"start-date"}
                                    sx={{marginRight: "14px"}}
                                    label={"Repeat Start Date/Time"}
                                    value={ scheduleData.repeatStartDate ? eugeneDatefromUtc(scheduleData.repeatStartDate) : null}
                                    onChange={(date) => handleDateChange(moment(date), "schedule-start-date")}
                                />
                        </FormControl>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <FormControl
                            className={classes.margin}
                            fullWidth={true}
                        >
                            {/*<KeyboardDateTimePicker*/}
                            {/*    error={fieldErrors.includes("invalid-date")}*/}
                            {/*    className={classes.dateMargins}*/}
                            {/*    disableToolbar={false}*/}
                            {/*    inputVariant={"outlined"}*/}
                            {/*    variant="inline"*/}
                            {/*    format="MM/DD/YYYY - hh:mm a"*/}
                            {/*    margin="normal"*/}
                            {/*    id="end-date"*/}
                            {/*    label="Repeat End Date/Time"*/}
                            {/*    value={eugeneDatefromUtc(scheduleData.repeatEndDate)}*/}
                            {/*    onChange={(date) => handleDateChange(date, "schedule-end-date")}*/}
                            {/*    KeyboardButtonProps={{*/}
                            {/*        "aria-label": "change date",*/}
                            {/*    }}*/}
                            {/*/>*/}
                                <DateTimePicker
                                    // id={"end-date"}
                                    sx={{marginRight: "14px"}}
                                    label={"Repeat End Date/Time"}
                                    value={eugeneDatefromUtc(scheduleData.repeatEndDate)}
                                    onChange={(date) => handleDateChange(moment(date), "schedule-end-date")}
                                />
                        </FormControl>
                    </Grid>
                    <Grid item={true} xs={12} style={{textAlign: "center"}}>
                        <FormControl required={true}>
                            <FormLabel>Select Days</FormLabel>
                            {
                                fieldErrors.includes("empty-days") &&
                                <FormHelperText className={classes.daySelectErrorText}>
                                    Please select at least one day
                                </FormHelperText>
                            }
                            <FormGroup row={true} className={classes.margin} >
                                {
                                    daysOfTheWeek.map((day, index) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={day}
                                                        name={day}
                                                        key={`${day}-checkbox`}
                                                        onChange={changeHandler}
                                                        checked={scheduleData[day.toLowerCase()]}
                                                    />
                                                }
                                                label={day}
                                                key={`${day}-form-control`}
                                            />
                                        )
                                    })
                                }
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid container={true} className={classes.root} spacing={4} justify={"center"}>
                        <Grid item={true}>
                            <H2Text>All Slot Times in Eugene, OR, time zone</H2Text>
                        </Grid>
                        {
                            generateSlots()
                        }
                    </Grid>
                    <Grid item={true} xs={12}>
                        <div className={classes.buttonDivStyles}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={addSlot}
                            >
                                Add a Slot +
                            </Button>
                        </div>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <div className={classes.buttonDivStyles}>
                            <Button
                                variant={"outlined"}
                                color={"primary"}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
