import {
    Button,
    Dialog,
    DialogTitle,
    FormControlLabel,
    FormHelperText, Grid, InputLabel, MenuItem, Select,
    Switch, Typography
} from "@material-ui/core"
import {ChangeEvent, default as React} from "react"
import ApiService from "../../../services/ApiService"
import {PanelGraphWithLanguage} from "../../../types/types"
import {messageStore} from "../../common/messages/MessageStore"
import {SSOTeacherAssessmentConfirm} from "./SSOTeacherAssessmentConfirm"
import {DatePicker} from "@mui/x-date-pickers-pro"
import moment from "moment/moment";
import {Moment} from "moment";

export interface SSOTeacherTestSetupProps {
    onClose: () => void,
    product_package: string,
    product_package_id: string,
    groupId: number,
    allowHandwritten: boolean,
    nameOfClass: string | null
}

interface SSOTeacherTestSetupState {
    useHandwritten: boolean,
    selectPanelGraph: number,
    selectPanelGraphId: number,
    estDate: Date | null,
    currentTests: PanelGraphWithLanguage[] | null,
    confirmOpen: boolean,
    languageSelected: string
}

export class SSOTeacherAssessmentSetup extends React.Component<SSOTeacherTestSetupProps, SSOTeacherTestSetupState, {}> {

    constructor(props: SSOTeacherTestSetupProps) {
        super(props)

        const defaultDate = new Date()
        defaultDate.setDate(defaultDate.getDate()+1)

        this.state = {
            useHandwritten : this.props.allowHandwritten,
            selectPanelGraph : -1,
            selectPanelGraphId: 0,
            estDate: defaultDate,
            currentTests: null,
            confirmOpen: false,
            languageSelected: ""
        }
    }

    componentDidMount(): void {
        if (this.props.product_package) {
            ApiService.getCurrentPanelGraphs(this.props.product_package_id)
                .then(response => {
                    if (response.length) {
                        this.setState({currentTests: response})
                    }
                })
        }
    }

    handleClose = () => {this.props.onClose()}

    closeConfirmModal = () => {
        this.setState({confirmOpen: false})
    }

    toggleTestingAllowed = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({useHandwritten: event.target.checked})
    }

    handleLanguageChange = (event: React.ChangeEvent<{ name?: string; value: any }>): void => {
        const currentTests = this.state.currentTests
        let index = event.target.value
        if (currentTests && index >= 0) {
            this.setState({
                selectPanelGraph: index as number,
                selectPanelGraphId: currentTests[index].panelGraphId as number,
                languageSelected: currentTests[index].languageName as string
            })
        } else {
            this.setState({
                selectPanelGraph: -1 as number
            })
        }
    }

    handleDateChange = (date: Moment | null): void => {
        if(date) {
            const dObj = new Date(date.toString())
            this.setState({estDate: dObj})
        }
    }

    openConfirmDialog = (): void  => {
        if (this.state.selectPanelGraph === -1) {
            messageStore.setErrorMessage("You must select a language.")
        } else {
            this.setState({confirmOpen: true})
        }

    }



    render() {
        const testList = this.state.currentTests


            return (
                <>
                    (<Dialog onClose={this.handleClose} aria-labelledby="Setup Test" open={true}>
                    <DialogTitle id="simple-dialog-title">Setup Test</DialogTitle>

                    <Grid container={true} alignItems="center" spacing={5} justifyContent="center" direction="column"
                          style={{padding: "10px"}}>
                        <Grid item={true} xs={12}>
                            <Typography>{this.props.product_package}</Typography>
                                <DatePicker
                                    value={this.state.estDate ? moment(this.state.estDate) : undefined}
                                    onChange={this.handleDateChange}
                                    disablePast={true}
                                />
                            <FormHelperText style={{width: "200px"}}>Estimated date of when the test will be
                                given.</FormHelperText>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <InputLabel id="label">Language to Test</InputLabel>
                            <Select id="testingLanguage" value={this.state.selectPanelGraph}
                                    onChange={this.handleLanguageChange}>
                                <MenuItem key={-1} value={-1}>Select Language</MenuItem>)
                                {testList && (
                                    testList.map((test: PanelGraphWithLanguage, index) => {
                                        return (<MenuItem key={index}
                                                          value={index}> {test.languageName}</MenuItem>)
                                    })
                                )}
                            </Select>
                            <FormHelperText style={{width: "200px"}}>The language subject of the test.</FormHelperText>
                        </Grid>
                        {this.props.allowHandwritten && (
                            <Grid item={true} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={this.toggleTestingAllowed}
                                            checked={this.state.useHandwritten}
                                            inputProps={{"aria-label": "Use handwritten form for written parts of the test."}}
                                        />
                                    }
                                    label="Use Handwritten"
                                />
                                <FormHelperText style={{width: "200px"}}>Use handwritten form for written parts of the
                                    test.</FormHelperText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container={true} alignItems="center" spacing={5} justifyContent="center" direction="row"
                          style={{padding: "10px"}}>
                        <Grid item={true} xs={6}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={this.openConfirmDialog}
                                aria-label={"Save test Settings"}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={this.handleClose}
                                aria-label={"Close with out saving"}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                    {this.state.confirmOpen && (
                        <SSOTeacherAssessmentConfirm
                            groupId={this.props.groupId}
                            onClose={this.closeConfirmModal}
                            closeBoth={this.handleClose}
                            product_package={this.props.product_package}
                            useHandwritten={this.state.useHandwritten}
                            estDate={this.state.estDate!}
                            nameOfClass={this.props.nameOfClass}
                            languageSelected={this.state.languageSelected}
                            panelGraphId={this.state.selectPanelGraphId}
                        />
                        )}
                </>
            )


    }


}
