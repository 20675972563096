/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/

import React, {lazy, Suspense} from "react"
import {Redirect, Route,  RouteComponentProps,  Switch} from "react-router-dom"
import {AdminAdvanceUserList} from "../../components/admin-portal/AdminAdvanceUserList"
import {AdminProctor} from "../../components/admin-portal/AdminProctor/AdminProctor"
import {BulkStampSubsetPanelGraphSetter} from "../../components/admin-portal/BulkStampSubsetPanelGraphSetter"
import {CreateAssessment} from "../../components/admin-portal/CreateAssessment"
import {CredlyReportTool} from "../../components/admin-portal/CredlyReportTool/CredlyReportTool"
import {CreateLicenseContainer} from "../../components/admin-portal/license/CreateLicenseContainer"
import {SampleTestLink} from "../../components/admin-portal/SampleTestLink"
import {SetTestCodePanelGraph} from "../../components/admin-portal/SetTestCodePanelGraph"
import {SetTestCodeRecorder} from "../../components/admin-portal/SetTestCodeRecorder"
import {UploadCredlyFromFile} from "../../components/admin-portal/UploadCredlyFromFile"
import {AdvanceEula} from "../../components/advance/advance-eula/AdvanceEula"
import ClaimLicenseKey from "../../components/advance/register-user/ClaimLicenseKey"
import RegisterUser from "../../components/advance/register-user/RegisterUser"
import AdvanceReportEducators from "../../components/AdvanceReport/AdvanceReportEducators"
import AptProfileFormContainer from "../../components/apt/AptProfileFormContainer"
import CodeGen2Container from "../../components/CodeGen/CodeGen2Container"
import AutoStartDashboard from "../../components/Dashboard/AutoStartDashboard"
import Dashboard from "../../components/Dashboard/Dashboard"
import {Login, TestLogin} from "../../components/Login/Login"
import LoginContinueTest from "../../components/Login/LoginContinueTest"
import {LoginStartOrContinue} from "../../components/Login/LoginStartOrContinue"
import {NewPassword} from "../../components/Login/NewPassword"
import {ResetPassword} from "../../components/Login/ResetPassword"
import SampleLogin from "../../components/Login/SampleLogin"
import Logout from "../../components/Logout/Logout"
import {PageNotFound} from "../../components/PageNotFound"
import PlaceProfileFormContainer from "../../components/place/PlaceProfileFormContainer"
import ProfileLoginName from "../../components/Profile/ProfileLoginName"
import ReadyProfile from "../../components/Profile/ReadyProfile"
import Stamp4SProfileFormContainer from "../../components/stamp4s/Stamp4SProfileFormContainer"
import Stamp4SeProfileFormContainer from "../../components/stamp4se/Stamp4SeProfileFormContainer"
import WorldSpeakProfileForm from "../../components/worldspeak/WorldSpeakProfileForm"
import {MANAGE_ADMIN_PORTAL_LOGINS, MANAGE_ADVANCE_DISTRICT_LOGINS, MANAGE_PLACE_DISTRICT_LOGINS, MANAGE_PUBLIC_API_LOGINS} from "../../util/Constants"
import {SearchItemsPage} from "../adminSearchItems/SearchItemsPage"
import {TestCodeManagement} from "../adminTestCodeManagement/TestCodeManagement"
import SSOLoginPageForClassLink from "../classlink/ClasslinkLoginpage"
import {authStore} from "../common/authentication/AuthStore"
import {LoadingSpinner} from "../common/loaders/LoadingSpinner"
import DIYLoginPage from "../login/DIYLogin/DIYLoginPage"
import OneTimeLoginTake from "../login/OneTimeLoginTake"
import {SSOLogin} from "../login/SSOLoginPage"
import SSOLoginPageForNYCDOE from "../nycdoe/NYCDOELoginPage"
import CredlyIndividuaReportContainer from "../reports/individual/components/CredlyIndividualReport"
import {ReportSubPaths} from "../reports/ReportSubPaths"
import {AvantProctorPage} from "../roster/AvantProctorPage"
import {RosterPage} from "../roster/RosterPage"
import {LocationState, RosterSelectTake} from "../roster/RosterSelectTake"
import {ScheduleProctorPage} from "../roster/ScheduleProctorPage"
import {SSODistrictReports} from "../sso/districtAdmin/components/SSODistrictReports"
import {SSODistrictSetup} from "../sso/districtAdmin/components/SSODistrictSetup"
import {ssoDistrictAdminLandingPage} from "../sso/districtAdmin/SSODistrictAdmin"
import SAMLLoginPage from "../sso/SAML/SAMLLandingPage"
import SAMLWelcomeCenterContainer from "../sso/SAML/SAMLWelcomeCenterContainer"
import {ssoStudentLandingPage} from "../sso/Student"
import {SSOTeacherLandingPage} from "../sso/Teacher"
import {SSOUnauthorizedLanding, SSOUnauthorizedLandingProps} from "../sso/unauthorized/SSOUnauthorizedLanding"
import PrivateRoute from "./PrivateRoute"
// These imports will be lazing loaded in to separate chunks make the main and first chuck loads smaller.
const ItemContainer = lazy(() => import("../../components/ItemContainer"))
const DevTools = lazy(() => import("../../components/admin-portal/DevTools/DevTools"))
const AdminDashboard = lazy(() => import("../../components/admin-portal/AdminDashboard"))
const ReportsPage = lazy(() => import("../reports/common/components/ReportsPage"))
const EmailChangeTool = lazy(() => import("../../components/admin-portal/EmailChangeTool"))
const AdminPasswordChange = lazy(() => import("../../components/admin-portal/AdminPasswordChange"))
const ViewLicensesContainer = lazy(() => import("../../components/admin-portal/license/ViewLicensesContainer"))
const SSOTool = lazy(() => import("../../components/admin-portal/SSOTool"))
const ClientSupportContainer = lazy(() => import("../adminClientSupportTool/ClientSupportContainer"))
const BillingContainer = lazy(() => import("../adminClientSupportTool/BillingContainer"))
const UploadRosterFromFile = lazy(() => import("../../components/admin-portal/UploadRosterFromFile"))
const TechCheck = lazy(() => import("../../components/TechCheck/TechCheck"))
const AdvanceReportDashboard = lazy(() => import("../../components/AdvanceReport/AdvanceReportDashboard"))
const UploadResponses = lazy(() => import("../../components/admin-portal/uploadResponses/UploadResponses"))
const ItemAnalysisPage = lazy(() => import("../adminItemAnalysis/ItemAnalysisPage"))
const SkillPyramidTest = lazy(() => import("../testsVisual/SkillPyramidTest"))
const ItemViewerPage = lazy(() => import("../adminItemViewer/ItemViewerPage"))
const ManageGroupsPage = lazy(() => import("../adminManageGroups/ManageGroupsPage"))
const ManageUsersPage = lazy(() => import("../adminManageUsers/ManageUsersPage"))
const SelfEvaluation = lazy(() => import("../../components/SelfEvaluation/SelfEvaluation"))
const AdvanceDashboard = lazy(() => import("../../components/advance/AdvanceDashboard"))
const AdvanceFinishModuleContainer = lazy(() => import("../../components/advance/finish-module/AdvanceFinishModuleContainer"))
const AdvancePracticeTestContainer = lazy(() => import("../../components/advance/practice-test/AdvancePracticeTestContainer"))
const ResetReportTool = lazy(() => import("../../app/adminClientSupportTool/ResetReportTool"))
const AdminResetCleverClassGroup = lazy(() => import("../../components/admin-portal/AdminResetSSOClassGroup"))
const AdminManageSpecialLogins = lazy(() => import("../../components/admin-portal/AdminManageSpecialLogins"))
const SSOSAMLDistrictCustomerRedirect = lazy(() => import("../sso/districtAdmin/SSOSAMLDistrictCustomerRedirect"))

export class RoutePaths {
    // LOGINS
    static readonly LOGIN = "/login"
    static readonly TEST = "/test"
    static readonly LOGOUT = "/close-session"
    static readonly ADVANCE_LOGIN = `${RoutePaths.LOGIN}?advance=true`
    static readonly TECH_CHECK = "/tech-check"
    static readonly SSO_STUDENT = "/sso/student"
}

export class Routes extends React.Component {
    isUserHasAnyPermission = (permissionsToCheck: string[]): boolean => {
        // Early return (do not deny permission) if we have an empty list of permissions to check against:
        if (permissionsToCheck.length < 1) {
            return true
        }

        return Boolean(
            authStore &&
            authStore.auth &&
            authStore.auth.userPermissions &&
            // There is overlap between the two arrays:
            authStore.auth.userPermissions.filter(permission => permissionsToCheck.includes(permission)).length > 0
        )
    }

    render() {
        // @ts-ignore
        return (
            <div className="routes">
                <Suspense fallback={<LoadingSpinner/>}>
                    <Switch>
                        <Route path={RoutePaths.TEST} component={TestLogin}/>
                        <Route path={RoutePaths.LOGIN} component={Login}/>
                        <Route path="/sample" component={SampleLogin}/>
                        <Route path="/saml" component={SAMLLoginPage}/>
                        <Route path="/claim" component={ClaimLicenseKey}/>
                        <Route path="/register" component={RegisterUser}/>
                        <Route path="/reset-password" component={ResetPassword}/>
                        <Route path="/new-password" component={NewPassword}/>
                        <Route path={RoutePaths.TECH_CHECK} component={TechCheck}/>
                        <Route path="/clever/login" component={SSOLogin} />
                        <Route path="/classlink/login" component={SSOLoginPageForClassLink} />
                        <Route exact={true} path={"/otlt/:takeId/:otpToken"} component={OneTimeLoginTake} />
                        <Route exact={true} path={"/proctored/:loginId"} component={DIYLoginPage} />
                        <Route path="/sso/sso_unauthorized"
                         component={(props: SSOUnauthorizedLandingProps) => <SSOUnauthorizedLanding {...props} turnOffHeader={true} />}
                         exact={true}/>
                        {/* logout route */}
                        <PrivateRoute
                            path={RoutePaths.LOGOUT}
                            component={Logout}
                        />
                        <Route
                            path="/sso/sso_unauthorized"
                            component={(props: SSOUnauthorizedLandingProps) => <SSOUnauthorizedLanding {...props} turnOffHeader={true} />}
                            exact={true}
                        />

                        {/* NYCDOE Routes */}
                        {/*/logion is for ngrok local development and should be removed once NYCDOE fixes our local redirect URL*/}
                        <Route path="/nycdoe/logion" component={SSOLoginPageForNYCDOE} />
                        <Route path="/nycdoe/login" component={SSOLoginPageForNYCDOE} />

                        {/* Reports routes */}
                        <PrivateRoute path={ReportSubPaths.REPORTS_BASE} component={ReportsPage}/>

                        <Route exact={true} path={"/credly/individual/:takeUuid"} component={CredlyIndividuaReportContainer}/>

                        {/* Roster Routes */}
                        <Route
                            exact={true}
                            path="/roster/:loginId"
                            render={(props) => <RosterPage isMiddlebury="true" {...props}/>}
                        />

                        <Route
                            exact={true}
                            path="/proctor-registration/:loginId"
                            render={(props) => <RosterPage isMiddlebury="false" {...props}/>}
                        />
                        <Route
                            exact={true}
                            path="/proctor-scheduling/:loginId"
                            render={(props) => <ScheduleProctorPage {...props}/>}
                        />
                        <Route
                            exact={true}
                            path="/avantproctor-registration/:loginId"
                            render={(props) => <AvantProctorPage isMiddlebury="false" {...props}/>}
                        />
                        <Route
                            exact={true} path="/proctor/select-takes"
                            render={(props) => <RosterSelectTake {...props as RouteComponentProps<{}, {}, LocationState>} />}
                        />

                        <PrivateRoute
                            path="/login-start-or-continue"
                            component={LoginStartOrContinue}
                        />
                        <PrivateRoute
                            path="/login-continue-test"
                            component={LoginContinueTest}
                        />

                        {/* Item Container */}
                        <PrivateRoute
                            path="/item/:itemPosition?"
                            component={ItemContainer}
                        />

                        {/* Profile Routes */}
                        <PrivateRoute
                            path="/profile-login-name"
                            component={ProfileLoginName}
                        />
                        <PrivateRoute path="/dashboard" component={Dashboard}/>
                        <PrivateRoute
                            path="/self-evaluation"
                            component={SelfEvaluation}
                        />
                        <PrivateRoute
                            path="/auto-start-dashboard"
                            component={AutoStartDashboard}
                        />

                        {/* ADVANCE Routes */}
                        <PrivateRoute path="/advance-eula" component={AdvanceEula}/>
                        <PrivateRoute
                            path="/advance-dashboard"
                            component={AdvanceDashboard}
                        />
                        <PrivateRoute
                            path="/advance-report"
                            component={AdvanceReportDashboard}
                        />
                        <PrivateRoute
                            path="/advance-report-educators"
                            component={AdvanceReportEducators}
                        />
                        <PrivateRoute
                            path="/advance-finish-module"
                            component={AdvanceFinishModuleContainer}
                        />
                        <PrivateRoute path="/advance-practice-test" component={AdvancePracticeTestContainer}/>

                        {/* APT Routes */}
                        <PrivateRoute
                            path="/apt-profile"
                            component={AptProfileFormContainer}
                        />

                        {/* PLACE Routes */}
                        <PrivateRoute
                            path="/place-profile"
                            component={PlaceProfileFormContainer}
                        />

                        {/* STAMP 4S Routes */}
                        <PrivateRoute
                            path="/stamp-4s-profile"
                            component={Stamp4SProfileFormContainer}
                        />

                        {/* STAMP 4Se Routes */}
                        <PrivateRoute
                            path="/stamp-4se-profile"
                            component={Stamp4SeProfileFormContainer}
                        />

                        {/* WorldSpeak Routes */}
                        <PrivateRoute
                            path="/worldspeak-profile"
                            component={WorldSpeakProfileForm}
                        />

                        {/* READY Routes */}
                        <PrivateRoute path="/ready-profile" component={ReadyProfile}/>

                        <PrivateRoute path="/products/:productId/items/:itemId/admin-view" component={ItemViewerPage}/>

                        {/* QA Routes */}
                        <PrivateRoute path="/qa/skillpyramid" component={SkillPyramidTest}/>
                        <PrivateRoute path="/qa/test-code-create" component={CreateAssessment}/>


                        {/* SSO Routes */}
                        <PrivateRoute path="/sso/teacher" turnOffHeader={true} component={SSOTeacherLandingPage}/>
                        <PrivateRoute path="/sso/student" turnOffHeader={true} component={ssoStudentLandingPage}/>
                        <PrivateRoute path="/sso/distadmin" turnOffHeader={true} component={ssoDistrictAdminLandingPage} exact={true}/>
                        <PrivateRoute path="/sso/distadmin/setup" component={SSODistrictSetup} exact={true}/>
                        <PrivateRoute path="/sso/distadmin/reports" component={SSODistrictReports} exact={true}/>

                        {/* SAML routes */}
                        <PrivateRoute path="/sso/saml/welcome-center" component={SAMLWelcomeCenterContainer}/>
                        <PrivateRoute path="/sso/saml/district/report/redirect" component={SSOSAMLDistrictCustomerRedirect}/>

                        {/* Admin Routes */}
                        <PrivateRoute

                            path="/admin-dashboard"
                            render={() => {
                                const basePath = "/admin-dashboard/"
                                const routes = [
                                    {
                                        name: "Create Licenses",
                                        path: `${basePath}admin-create-licenses`,
                                        component: CreateLicenseContainer
                                    },
                                    {
                                        name: "View Licenses",
                                        path: `${basePath}admin-view-licenses`,
                                        component: ViewLicensesContainer
                                    },
                                    {
                                        name: "View Advance Users",
                                        path: `${basePath}admin-view-advance-users`,
                                        component: AdminAdvanceUserList
                                    },
                                    {
                                        name: "Content Viewer",
                                        path: `${basePath}admin-search-items`,
                                        component: SearchItemsPage
                                    },
                                    {
                                        name: "Client Support Tool",
                                        path: `${basePath}admin-client-support-tool`,
                                        component: ClientSupportContainer
                                    },
                                    {
                                        name: "Email Change Tool",
                                        path: `${basePath}email-change-tool`,
                                        component: EmailChangeTool
                                    },
                                    {
                                        name: "Password Change Tool",
                                        path: `${basePath}change-password`,
                                        component: AdminPasswordChange
                                    },
                                    {
                                        name: "Billing",
                                        path: `${basePath}admin-billing-tool`,
                                        component: BillingContainer
                                    },
                                    {
                                        name: "Reset Reports",
                                        path: `${basePath}billing-reset-retakes`,
                                        component: ResetReportTool
                                    },
                                    // TODO: Use this as the base of new CST tool, it uses material table and has the first serch field setup
                                    // {
                                    //     name: "Proctored User Tool",
                                    //     path: `${basePath}proctor-track-tool`,
                                    //     component: ProctorTrackTool
                                    // },
                                    {
                                        name: "Sample Test Links",
                                        path: `${basePath}sample-test-links`,
                                        component: SampleTestLink
                                    },
                                    {
                                        name: "Update Test-Code Voice Recorder",
                                        path: `${basePath}update-voice-recorder`,
                                        component: SetTestCodeRecorder
                                    },
                                    {
                                        name: "Test Code Managment",
                                        path: `${basePath}test-code-managment`,
                                        component: TestCodeManagement
                                    },
                                    {
                                        name: "Manage Groups",
                                        path: `${basePath}manage-groups`,
                                        component: ManageGroupsPage
                                    },
                                    {
                                        name: "Manage Users",
                                        path: `${basePath}manage-users`,
                                        component: ManageUsersPage
                                    },
                                    {
                                        name: "Item Analysis",
                                        path: `${basePath}item-analysis`,
                                        component: ItemAnalysisPage
                                    },
                                    {
                                        name: "Roster Upload",
                                        path: `${basePath}upload-roster`,
                                        component: UploadRosterFromFile
                                    },
                                    {
                                        name: "Handwritten Responses Upload",
                                        path: `${basePath}upload-responses`,
                                        component: UploadResponses
                                    },
                                    {
                                        name: "Credly Upload",
                                        path: `${basePath}upload-credly`,
                                        component: UploadCredlyFromFile
                                    },
                                    {
                                        name: "Credly Reports",
                                        path: `${basePath}credly-reports`,
                                        component: CredlyReportTool
                                    },
                                    {
                                        name: "Update Test Code Panel Graph",
                                        path: `${basePath}update-panel-graph`,
                                        component: SetTestCodePanelGraph
                                    },
                                    {
                                        name: "Bulk 2s/e & 3s/e Panel Graph Setter",
                                        path: `${basePath}bulk-stamp-subset-panel-graph-setter`,
                                        component: BulkStampSubsetPanelGraphSetter
                                    }
                                    ,
                                    {
                                        name: "SSO Class Group Reset",
                                        path: `${basePath}reset-sso-class-group`,
                                        component: AdminResetCleverClassGroup
                                    },
                                    {
                                        name: "SSO Tool",
                                        path: `${basePath}sso-tool`,
                                        component: SSOTool
                                    },
                                    // TODO: Re-add the below once work on ne sso entity tool is complete and persmissions added
                                    // {
                                    //     name: "SSO Entity Tools",
                                    //     path: `${basePath}sso-entity-tools`,
                                    //     component: SSOEntityTool
                                    // },
                                    {
                                        name: "Dev Tools",
                                        path: `${basePath}dev-tools`,
                                        component: DevTools
                                    },
                                    {
                                        name: "Proctor",
                                        path: `${basePath}proctor`,
                                        component: AdminProctor
                                    },
                                    {
                                        name: "Manage Special Logins",
                                        path: `${basePath}manage-special-logins`,
                                        component: AdminManageSpecialLogins,
                                        hidden: !this.isUserHasAnyPermission([MANAGE_ADMIN_PORTAL_LOGINS, MANAGE_ADVANCE_DISTRICT_LOGINS, MANAGE_PLACE_DISTRICT_LOGINS, MANAGE_PUBLIC_API_LOGINS])
                                    },
                                    {
                                        name: "CodeGen-2",
                                        path: `${basePath}codegen-2`,
                                        component: CodeGen2Container
                                    },
                                ]

                                return (
                                    <AdminDashboard sideBarOptions={routes.map(({name, path, hidden}) => ({name, path, hidden}))}>
                                        <Switch>
                                            {routes.map(({name, path, component, hidden}, idx) => (
                                                !hidden &&
                                                <PrivateRoute
                                                    name={name}
                                                    path={path}
                                                    component={component}
                                                    key={idx}
                                                    turnOffHeader={true}
                                                />
                                            ))}
                                        </Switch>
                                    </AdminDashboard>
                                )
                            }}
                        />
                        <Redirect exact={true} path="/" to="/test"/>
                        <Route component={PageNotFound}/>
                    </Switch>
                </Suspense>
            </div>
        )
    }
}
