import {ButtonBase} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Fade from "@material-ui/core/Fade"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import List from "@material-ui/core/List"
import Popper from "@material-ui/core/Popper"
import TextField, {TextFieldProps} from "@material-ui/core/TextField"
import {CalendarToday} from "@material-ui/icons"
import {StaticDatePicker} from "@mui/x-date-pickers-pro"
import moment from "moment"
import React from "react"
import MaskedInput from "react-text-mask"
import {AvantColors} from "../../styles/AvantColors"
import {H5Text, SmallBodyText} from "../../styles/AvantTypography"
import {theme} from "../../styles/MuiThemes"
import {Moment} from "../../types/types"
import {log, prettyJson} from "../../util/Logging"
import {AvantDateRange, TimeUtil} from "../../util/TimeUtil"
import {AvantButtonLink} from "./AvantButton"
import {AvantPaper} from "./AvantPaper"
import {AvantVerticalDivider} from "./AvantVerticalDivider"
import {ListItemLink} from "./ListItemLink"
import {WithStyles, withStyles} from "@material-ui/styles"

const AvantDateRangePickerStyles = {
    datePicker: {
        height: 35,
        width: 232,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1)
    },
    button: {
        height: 33,
        width: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0px 4px 4px 0px"
    }
}

export interface DatePickerOption {
    name: string
    value: string
    selected?: boolean
}

interface AvantDateRangePickerProps extends WithStyles<typeof AvantDateRangePickerStyles> {
    style?: {
        container?: React.CSSProperties,
        datePicker?: React.CSSProperties,
        button?: React.CSSProperties
    }
    dateRange?: AvantDateRange

    /**
     * This component will navigate to the given link when apply is clicked.
     */
    createLink: (dateRange: AvantDateRange, selectedValues: string[]) => string
    options?: DatePickerOption[]
    showTitle: boolean
}

interface AvantDateRangePickerState {
    open: boolean
    startDate: string
    endDate: string
    selectedOptions: string[]
}

class AvantDateRangePicker extends React.Component<AvantDateRangePickerProps, AvantDateRangePickerState> {
    buttonRef: React.RefObject<HTMLButtonElement> = React.createRef()

    constructor(props: AvantDateRangePickerProps) {
        super(props)

        this.state = {
            open: false,
            startDate: "",
            endDate: "",
            selectedOptions: []
        }
    }

    componentDidMount() {
        const {dateRange, options} = this.props
        const startDate = dateRange && dateRange.startDate
            ? TimeUtil.formatToUsDate(dateRange.startDate)
            : ""

        const endDate = dateRange && dateRange.endDate
            ? TimeUtil.formatToUsDate(dateRange.endDate)
            : ""

        const selectedOptions = options != null
            ? options.filter(option => option.selected).map(option => option.value)
            : []

        this.setState({
            startDate: startDate,
            endDate: endDate,
            selectedOptions: selectedOptions
        })
    }

    generateLink = (range?: AvantDateRange) => {
        log.debug("Generating link with options: " + this.state.selectedOptions)
        return this.props.createLink({
            startDate: range ? range.startDate : this.startMoment,
            endDate: range ? range.endDate : this.endMoment
        }, this.state.selectedOptions)
    }

    datesAreValid = () => {
        if (this.state.selectedOptions.length === 0) {
            return false
        }
        // Dates in future are valid because they might be creating a link to a report that will be populated in the future.
        return !this.startMoment.isAfter(this.endMoment)

    }

    get startMoment(): Moment {
        return TimeUtil.usDateStringToMoment(this.state.startDate)
    }

    get endMoment(): Moment {
        return TimeUtil.usDateStringToMoment(this.state.endDate)
    }

    updateOptions = (value: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (this.state.selectedOptions != null) {
                log.debug(`Updating date picker option: ${value} with ${checked} selected: ${prettyJson(this.state.selectedOptions)}`)
                if (checked) {
                    this.state.selectedOptions.push(value)
                } else {
                    this.setState({
                        selectedOptions: this.state.selectedOptions.filter(selected => selected !== value)
                    })
                }
                log.debug(`Updating date picker option: ${value} with ${checked} selected: ${prettyJson(this.state.selectedOptions)}`)
            }
        }
    }

    render() {
        const {style, options, classes} = this.props
        const {open, startDate, endDate, selectedOptions} = this.state
        const nowMoment = moment()

        const containerStyles = style && style.container
        // Add the border styles to what styles are passed from the parent component
        const datePickerStyles = {
            border: open ? `1px solid ${AvantColors.REPORT_BOX_BORDER_BLUE}` : `1px solid ${AvantColors.REPORT_BOX_BORDER}`,
            ...style && style.datePicker
        }

        const buttonStyles = {
            backgroundColor: open ? AvantColors.REPORT_BACKGROUND_BLUE : AvantColors.REPORT_BACKGROUND_GRAY_LIGHT,
            ...style && style.button
        }

        return (
            <div style={containerStyles}>

                { this.props.showTitle &&
                    <H5Text style={{marginBottom: 4}}>
                        DATE FILTER:
                    </H5Text>
                }
                <div
                    className={classes.datePicker}
                    style={datePickerStyles}
                >
                    <DateInput
                        onFocus={() => this.setState({open: true})}
                        value={startDate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({startDate: event.target.value})}
                    />
                    <SmallBodyText style={{marginRight: theme.spacing(1)}}>to</SmallBodyText>
                    <DateInput
                        onFocus={() => this.setState({open: true})}
                        value={endDate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({endDate: event.target.value})}
                    />
                    <div style={{flexGrow: 1}}/>
                    <ButtonBase
                        className={classes.button}
                        ref={this.buttonRef}
                        focusRipple={true}
                        onClick={() => {
                            this.setState({open: true})
                        }}
                        style={buttonStyles}
                    >
                        <CalendarToday color={open ? "primary" : "action"} style={{height: 16}}/>
                    </ButtonBase>
                </div>
                <Popper open={open} anchorEl={this.buttonRef.current} transition={true}>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div style={{paddingTop: theme.spacing(1)}}>
                                <AvantPaper style={{display: "flex"}}>
                                    <SingleCalendar
                                        forDate={this.startMoment}
                                        onChange={(date: Moment | null) => {
                                            if (date != null) {
                                                this.setState({startDate: TimeUtil.formatToUsDate(date)})
                                            }
                                        }}
                                        maxDate={this.endMoment}
                                    />
                                    <AvantVerticalDivider/>
                                    <SingleCalendar
                                        forDate={this.endMoment}
                                        onChange={(date: Moment | null) => {
                                            if (date != null) {
                                                this.setState({endDate: TimeUtil.formatToUsDate(date)})
                                            }
                                        }}
                                        minDate={this.startMoment}
                                    />
                                    <AvantVerticalDivider/>
                                    <div style={{width: 200, display: "flex", flexDirection: "column"}}>
                                        {options && <Options options={options} selected={selectedOptions} update={this.updateOptions} />}
                                        <List>
                                            <ListItemLink
                                                to={this.generateLink({
                                                    startDate: moment().subtract(3, "months"),
                                                    endDate: nowMoment
                                                })}
                                            >
                                                <H5Text>
                                                    Last 3 Months
                                                </H5Text>
                                            </ListItemLink>
                                            <ListItemLink
                                                to={this.generateLink({
                                                    startDate: moment().subtract(6, "months"),
                                                    endDate: nowMoment
                                                })}
                                            >
                                                <H5Text>
                                                    Last 6 Months
                                                </H5Text>
                                            </ListItemLink>
                                            <ListItemLink
                                                to={this.generateLink({
                                                    startDate: moment().subtract(1, "years"),
                                                    endDate: nowMoment
                                                })}
                                            >
                                                <H5Text>
                                                    Last Year
                                                </H5Text>
                                            </ListItemLink>
                                        </List>
                                        <div style={{flexGrow: 1}}/>
                                        <div style={{display: "flex"}}>
                                            <div style={{flexGrow: 1}}/>
                                            <Button
                                                style={{margin: theme.spacing(2)}}
                                                onClick={() => this.setState({open: false})}
                                                size={"small"}
                                            >
                                                Cancel
                                            </Button>
                                            <AvantButtonLink
                                                style={{margin: theme.spacing(2), marginLeft: 0}}
                                                size={"small"}
                                                to={this.generateLink()}
                                                disabled={!this.datesAreValid()}
                                            >
                                                Apply
                                            </AvantButtonLink>
                                        </div>
                                    </div>
                                </AvantPaper>
                            </div>
                        </Fade>
                    )}
                </Popper>
            </div>

        )
    }
}

const Options = (props: {
    options: DatePickerOption[],
    selected: string[],
    update: (value: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}) => (
    <FormGroup style={{margin: theme.spacing(2), marginBottom: 0}}>
        {props.options.map(option => (
            <FormControlLabel
                key={option.value}
                control={(
                    <Checkbox
                        checked={props.selected.includes(option.value)}
                        onChange={props.update(option.value)}
                        value={option.value}
                    />
                )}
                label={option.name}
            />
        ))}
    </FormGroup>
)

const SingleCalendar = (props: { forDate: Moment, onChange: (date: Moment | null) => void, maxDate?: Moment, minDate?: Moment }) => {
    return (
        <div style={{padding: theme.spacing(0.5)}}>
                <StaticDatePicker
                    slotProps={{
                        toolbar: {
                            hidden: true
                        },
                        actionBar: {
                            hidden: true
                        }
                    }}
                    openTo={"day"}
                    value={props.forDate}
                    onChange={props.onChange}
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                />
        </div>
    )
}

interface DateInputState {
   focused: boolean
}

class DateInput extends React.Component<TextFieldProps, DateInputState> {
    constructor(props: TextFieldProps) {
        super(props)

        this.state = {
            focused: false
        }
    }

    render() {
        const {onFocus, ...rest} = this.props
        const {focused} = this.state
        return (
            // @ts-ignore
            <TextField
                style={{marginRight: theme.spacing(1), width: 80, fontWeight: focused ? "bold" : undefined}}
                InputProps={{
                    disableUnderline: true,
                    // tslint:disable-next-line:no-any
                    inputComponent: DateMask as any
                }}
                inputProps={{
                    style: {
                        fontSize: 14,
                        color: AvantColors.REPORT_FONT_COLOR_GRAY_1
                    }
                }}
                onFocus={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    this.setState({focused: true})
                    const notNullFocus = onFocus!
                    notNullFocus(event)
                }}
                onBlur={() => this.setState({focused: false})}
                {...rest}
            />
        )
    }
}

interface DateMaskProps {
    inputRef: (ref: HTMLInputElement | null) => void
}

export const DateMask = (props: DateMaskProps) => {
    const {inputRef, ...other} = props

    return (
        <MaskedInput
            {...other}
            ref={
                // tslint:disable-next-line:no-any
                (ref: any) => {
                    inputRef(ref ? ref.inputElement : null)
                }
            }
            mask={[/[0-1]/, /\d/, "/", /[0-3]/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            showMask={true}
        />
    )
}

export default withStyles(AvantDateRangePickerStyles)(AvantDateRangePicker)
