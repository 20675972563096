import * as React from "react"
import ApiService from "../../../services/ApiService"
import MaterialTable from "material-table"
import {DateTimePicker} from "@mui/x-date-pickers-pro"
import {useState} from "react"
import {Button} from "@material-ui/core"
import axios from "axios"
import moment, {Moment} from "moment"
import {theme} from "../../../styles/MuiThemes"

function buildSort(orderByField: string | unknown, orderDirction: string): string {
    let sortString = ""
    if (orderByField) {
        sortString = `&sort=${orderByField},${orderDirction}`
    }
    return sortString
}

interface TableData {
    id: number,
    response: string,
    lasttouched: string,
    valid: string
}
export const ValidateRecordings: React.FC = () => {
    const tableRef = React.createRef<MaterialTable<TableData>>()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const handleStartDateChange = (d: Moment | null): void => {
        if (d) {
            const dObj = new Date(d.toString())
            setStartDate(dObj)
        }
    }
    const handleEndDateChange = (d: Moment | null): void => {
        if (d) {
            const dObj = new Date(d.toString())
            setEndDate(dObj)
        }
    }
    const handleReloadTable = (): void => {
        if (tableRef.current) {
            // @ts-ignore
            tableRef.current.onQueryChange()
        }
    }
    return (
        <>
            <h1>Logins</h1>
            <div>
                    <DateTimePicker
                        label="Start Date"
                        // inputVariant="outlined"
                        value={moment(startDate)}
                        onChange={handleStartDateChange}
                        sx={{
                            marginRight: theme.spacing(1),
                            marginBottom: "8px"
                        }}
                    />
                    <DateTimePicker
                        label="End Date"
                        // inputVariant="outlined"
                        value={moment(endDate)}
                        onChange={handleEndDateChange}
                        sx={{
                            marginRight: theme.spacing(1),
                            marginBottom: "8px"
                        }}
                    />
                <Button
                    variant={"outlined"}
                    onClick={handleReloadTable}
                >
                    Reload Table
                </Button>
                <Button
                    variant={"outlined"}
                    style={{marginLeft: "8px"}}
                >
                    Check Against S3
                </Button>
            </div>
            <MaterialTable
                tableRef={tableRef}
                title="Logins"
                columns={[
                    { title: "response id", field: "id" },
                    { title: "check", render: (rowData: any) => {
                        return (
                            <div>
                                {rowData.valid}
                            </div>
                        );
                    }},
                   
                    { title: "response", field: "response" },
                    { title: "lasttouched", field: "lasttouched", render: (rowData) => {
                        const d = new Date(rowData.lasttouched)
                        return d.toISOString()
                    } },
                ]}
                data={ query => {
                    return new Promise((resolve, reject) => {
                        const sort = (query.orderBy) ? buildSort(query.orderBy.field, query.orderDirection) : ""
                        ApiService.get(`${ApiService.API_URL}response/oggs?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&page=${query.page}&size=${query.pageSize}${sort}`).then(response => {
                            resolve({
                                data: response.data.content,
                                page: response.data.number,
                                totalCount: response.data.totalElements
                            })
                        })
                    })
                }}
                options={{
                    selection: true,
                    filtering: true,
                    search: false,
                    showTitle: true,
                    toolbar: true,
                    padding: "dense"
                }}
                actions={[
                    {
                        icon: "save",
                        tooltip: "Save User",
                        onClick: (event, rowData) => {
                            rowData.forEach((row: any) => {
                                axios.head(row.response).then(response => {
                                    // test
                                    row.valid = "valid"
                                }).catch(axiosEvent => {
                                    row.valid = "INVALID"
                                })
                                // Do save operation
                            })
                        }
                    }
                ]}
            />
        </>
    )
}
