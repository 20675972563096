import {LocalizationProvider} from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"
import React, {StrictMode} from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import "react-table/react-table.css"
import "setimmediate"
import "url-search-params-polyfill"
import {authStore} from "./app/common/authentication/AuthStore"
import {productStore} from "./app/common/products/ProductStore"
import {App} from "./components/App/App"
import store from "./redux/store"
import "./styles/index.scss"

authStore.loadAuth()
productStore.loadLoginProduct()

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <App/>
            </LocalizationProvider>
        </Provider>
     </StrictMode>
    ,
    document.getElementById("root") as HTMLElement
)
