import moment from "moment"
import {Dispatch} from "react"
import {NewSSOEntitiesAndGroupMaster, NewSSOEntitiesAndGroupMasterErrors} from "../components/SSOEntityTypes"
import {AddNewSSOEntityState, NewSSOEntityUpdateAction} from "./AddNewSSOEntityReducer"

/***
 * Function to find the insert location with log n complexity
 * Need to write a test. This should work for ANY object
 ***/
export function findInsertPosition<T>(sortedArray: T[], value: T, compareFunction: (a: T, b: T) => number): number {
    let low = 0
    let high = sortedArray.length

    while (low < high) {
        const mid = Math.floor((low + high) / 2)
        if (compareFunction(sortedArray[mid], value) < 0) {
            low = mid + 1
        } else {
            high = mid
        }
    }
    return low
}

/***
 * This function is used to validate the user input from the form to create a new sso entity. It takes the current state
 * and a dispatch function as arguments and returns true if all required fields contain valid inputs. If any required field
 * is null or has invalid values, this function will return false and the form will show the fields with errors
 ***/
export const validateFormData = (curState: AddNewSSOEntityState, dispatch: Dispatch<NewSSOEntityUpdateAction>): boolean => {

    const listOfNonNullAndNonEmptyStringFields = [
        "name", "ssoId", "testingStart", "testingEnd", "ssoSystem", "oppNumber", "oauth"
    ]

    let validForm: boolean = true

    Object.keys(curState.entity).forEach((field) => {
        console.log(`${field}`)
        const value = curState.entity[field as keyof NewSSOEntitiesAndGroupMaster]
        const errorField = `${field}Error` as keyof NewSSOEntitiesAndGroupMasterErrors

        // Check string fields that cannot be null or blank and set error
        if ((value === null || value === undefined || value.toString().trim() === "") && listOfNonNullAndNonEmptyStringFields.includes(field)) {
            validForm = false
            dispatch({type: "SET_ERROR_VALUE", fieldName: errorField, payload: `${field.toUpperCase()} field cannot be empty`})
        }

        // Check test start and end are valid and end is later the today and later than start date
        if ((field === "testingStart" || field === "testingEnd") && value === null) {
            validForm = false
            dispatch({type: "SET_ERROR_VALUE", fieldName: errorField, payload: `${field.toUpperCase()} must have a value`})
        }

        // Make sure that there is at least one valid License
        if (field === "licensesSold" && value === typeof [] && value.length < 1) {
            validForm = false
            dispatch({type: "SET_ERROR_VALUE", fieldName: errorField, payload: `${field.toUpperCase()} must have at least one license`})
        }

    })

    // Make sure a parent was selected
    if (curState.entity.parentGroupId === null) {
        validForm = false
        dispatch({type: "SET_ERROR_VALUE", fieldName: "parentGroupIdError", payload: "Parent group is required"})
    }

    // Make sure that the testing window is valid
    if (curState.entity.testingEnd && curState.entity.testingStart && curState.entity.testingEnd <= curState.entity.testingStart) {
        validForm = false
        dispatch({type: "SET_ERROR_VALUE", fieldName: "testingStartError", payload: "Testing end date must be later then testing start date"})
        dispatch({type: "SET_ERROR_VALUE", fieldName: "testingEndError", payload: "Testing end date must be later then testing start date"})
    } else if (curState.entity.testingEnd && curState.entity.testingEnd.isBefore(moment())) {
        validForm = false
        dispatch({type: "SET_ERROR_VALUE", fieldName: "testingEndError", payload: "Testing end date must be later then today"})
    }

    // Make sure that there is at least a district or customer group defined
    const district = curState.entity.districtGroupName
    const customer = curState.entity.customerGroupName

    if (district === null || district.trim() === "") {
        // This means that customer must not be null or empty string else error
        if (customer === null || customer.trim() === "") {
            validForm = false
            dispatch({type: "SET_ERROR_VALUE", fieldName: "districtGroupNameError", payload: "Must have a valid District or Customer Group Name"})
            dispatch({type: "SET_ERROR_VALUE", fieldName: "customerGroupNameError", payload: "Must have a valid District or Customer Group Name"})
        }
    }

    return validForm

}
