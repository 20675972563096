import {observer} from "mobx-react"
import moment from "moment"
import React from "react"
// import DatePicker from "react-datepicker"
import {RouteComponentProps} from "react-router"
import {groupMasterStore} from "../../../app/common/group/GroupMasterStore"
import {GroupMaster, GroupType, sortGroupMasterByName} from "../../../app/common/group/models/GroupMaster"
import {loadingSpinnerStore} from "../../../app/common/loaders/LoadingSpinnerStore"
import ApiService from "../../../services/ApiService"
import {ApiErrorResponse, ApiLicenseKey} from "../../../types/types"
import {SUPPORT_MESSAGE} from "../../../util/Constants"
import {GroupId, ProductId} from "../../../validation/ValidPrimaryKey"
import {theme} from "../../../styles/MuiThemes"
import Alert from "react-bootstrap/Alert"
import Grid from "@material-ui/core/Grid"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import {log} from "../../../util/Logging"
import {Button} from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import {AvantPaper} from "../../../app/generic-components/AvantPaper"
import {DatePicker} from "@mui/x-date-pickers-pro"

type Moment = moment.Moment

interface IFormErrors {
    oppCode: string[]
    numKeys: string[]
    languageCount: string[]
    expirationDate: string[]
}


type TComponentProps = RouteComponentProps<any>

interface IComponentState {
    error: boolean | string
    country?: number
    state?: number
    districtId?: number
    schoolId?: number
    oppCode: string
    languageCount: number
    numKeys: number
    product: string
    expirationDate?: Moment
    licenseKeys?: ApiLicenseKey[]
    countries?: GroupMaster[]
    states?: GroupMaster[]
    districts?: GroupMaster[]
    schools?: GroupMaster[]
    formErrors: IFormErrors
    hasFormErrors: boolean
}

@observer
export class CreateLicenseContainer extends React.Component<TComponentProps, IComponentState> {
    private readonly productNameToId = new Map<string, number>([["Advance", 8]])

    private readonly daysUntilExpiration = 379

    constructor(props: TComponentProps) {
        super(props)
        this.state = {
            error: false,
            formErrors: {
                oppCode: [],
                numKeys: [],
                languageCount: [],
                expirationDate: []
            },
            hasFormErrors: false,
            numKeys: 1,
            languageCount: 1,
            oppCode: "",
            // expirationDate: undefined,
            expirationDate: moment().add(this.daysUntilExpiration, "day"),
            product: "Advance"
        }

        this.handleStateChange = this.handleStateChange.bind(this)
        this.handleDistrictChange = this.handleDistrictChange.bind(this)
        this.handleSchoolChange = this.handleSchoolChange.bind(this)
        this.handleOppCodeChange = this.handleOppCodeChange.bind(this)
        this.handleNumKeysChange = this.handleNumKeysChange.bind(this)
        this.handleLanguageCountChange = this.handleLanguageCountChange.bind(this)
        this.handleExpirationChange = this.handleExpirationChange.bind(this)
        this.submit = this.submit.bind(this)
        this.download = this.download.bind(this)
    }

    componentDidMount() {
        ApiService.getGroupsByType(GroupType.STATE).then((states: GroupMaster[]) => {
            states.sort((a: GroupMaster, b: GroupMaster) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            })
            const state = states[0].id
            this.setState({states, state})
        })
        loadingSpinnerStore.hideLoadingSpinner = true

    }

    handleStateChange(e: React.ChangeEvent<{ value: unknown }>) {
        if (e.target !== null) {
            const stateId = parseInt(e.target.value as string, 10)
            this.getDistricts(new GroupId(stateId))
        }
    }

    getDistricts(stateId: GroupId) {
        groupMasterStore.getSubGroupsForGroup(stateId).then((groups: GroupMaster[]) => {
            this.setState({districts: sortGroupMasterByName(groups)})

            const districts: GroupMaster[] = []
            groups.forEach((group: GroupMaster) => {
                if (group.groupType === GroupType.DISTRICT) {
                    districts.push(group)
                }
            })
            let districtId = 0
            if (districts.length > 0) {
                districts.sort((a: GroupMaster, b: GroupMaster) => {
                    if (a.name < b.name) {
                        return -1
                    } else if (a.name > b.name) {
                        return 1
                    } else {
                        return 0
                    }
                })
                districtId = districts[0].id
            }
            this.setState({state: stateId.value(), districtId, districts})
            this.getSchools(new GroupId(districtId))
        })
    }

    handleDistrictChange(e: React.ChangeEvent<{ value: unknown }>) {
        if (e.target !== null) {
            const districtId = Number(e.target.value)
            this.setState({districtId})
            this.getSchools(new GroupId(districtId))
        }
    }

    getSchools(districtId: GroupId) {
        groupMasterStore.getSubGroupsForGroup(districtId).then((groups: GroupMaster[]) => {
            const schools: GroupMaster[] = []
            groups.forEach((group: GroupMaster) => {
                if (group.groupType === GroupType.SCHOOL) {
                    schools.push(group)
                }
            })
            let schoolId = 0
            if (schools.length > 0) {
                schools.sort((a: GroupMaster, b: GroupMaster) => {
                    if (a.name < b.name) {
                        return -1
                    } else if (a.name > b.name) {
                        return 1
                    } else {
                        return 0
                    }
                })
                schoolId = schools[0].id
            }
            this.setState({schoolId, schools})
        })
    }

    handleSchoolChange(e: React.ChangeEvent<{ value: unknown }>) {
        if (e.target !== null) {
            const schoolId = Number(e.target.value)
            this.setState({schoolId: schoolId})
        }
    }

    handleOppCodeChange(e: React.ChangeEvent<{ value: unknown }>) {
        this.setState({oppCode: e.target.value as string})
    }

    handleNumKeysChange(e: React.ChangeEvent<{ value: unknown }>) {
        const numKeys = Number(e.target.value)
        this.setState({numKeys})
    }

    handleLanguageCountChange(e: React.ChangeEvent<{ value: unknown }>) {
        const languageCount = Number(e.target.value)
        this.setState({languageCount})
    }

    handleExpirationChange(date: Moment | null) {
        if (date !== null && date !== undefined) {
            this.setState({expirationDate: date})
        }
    }

    hasFormErrors(): boolean {
        const formErrors: IFormErrors = {
            oppCode: [],
            numKeys: [],
            languageCount: [],
            expirationDate: []
        }

        let hasFormErrors: boolean = false
        if (this.state.oppCode == null || this.state.oppCode === "") {
            formErrors.oppCode.push("You must enter an opportunity code.")
            hasFormErrors = true
        }
        if (this.state.oppCode.length > 10) {
            formErrors.oppCode.push("The opportunity code may not have more than 10 characters.")
            hasFormErrors = true
        }
        if (this.state.numKeys == null || this.state.numKeys <= 0) {
            formErrors.numKeys.push("The number of keys must be greater than 0.")
            hasFormErrors = true
        }
        if (this.state.languageCount == null || this.state.languageCount <= 0) {
            formErrors.languageCount.push("The number of languages must be greater than 0.")
            hasFormErrors = true
        }
        if (this.state.expirationDate == null || this.state.expirationDate.isBefore(moment.now())) {
            formErrors.expirationDate.push("The must enter an expiration date that is after now.")
            hasFormErrors = true
        }

        this.setState({formErrors, hasFormErrors})

        return hasFormErrors
    }

    submit(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        ev.preventDefault()
        if (!this.hasFormErrors()) {
            const schoolId: number = this.state.schoolId ? this.state.schoolId : 0
            const oppCode: string = this.state.oppCode ? this.state.oppCode : ""
            const numKeys: number = this.state.numKeys ? this.state.numKeys : 1
            const languageCount: number = this.state.languageCount ? this.state.languageCount : 1
            const productId: number = this.productNameToId.get(this.state.product)!
            // const expirationDate: string = moment(this.state.expirationDate!).toISOString()
            const expirationDate: string = this.state.expirationDate ? this.state.expirationDate.toISOString() : ""
            ApiService
                .createLicenseKeys(
                    numKeys,
                    new ProductId(productId),
                    new GroupId(schoolId),
                    languageCount,
                    oppCode,
                    expirationDate
                )
                .then(
                    (licenseKeys: ApiLicenseKey[]) => {
                        this.setState({licenseKeys})
                    },
                    (error: ApiErrorResponse) => {
                        this.setState({
                            error: SUPPORT_MESSAGE
                        })
                        throw new Error(`Failed to create license keys. ${error.response.data.error}`)
                    }
                )
        }
    }

    download() {
        log.debug(`[CreateLicenseContainer.download]`)
        if (this.state.licenseKeys == null) {
            this.setState({
                error: SUPPORT_MESSAGE
            })
            throw Error("this.state.licenseKeys is undefined.")
        }

        // Create CSV
        let text: string = "License Key\n"
        if (this.state != null && this.state.licenseKeys != null) {
            const keys: ApiLicenseKey[] = this.state.licenseKeys
            keys.forEach((licenseKey: ApiLicenseKey) => {
                text += licenseKey.key + "\n"
            })
        }

        // Create hidden link
        const blob = new Blob([text], {type: "text/csv;charset=utf8;"})
        const element = document.createElement("a")
        document.body.appendChild(element)
        element.setAttribute("href", window.URL.createObjectURL(blob))
        element.setAttribute("download", "license_keys.csv")
        element.style.display = ""
        element.click()
        document.body.removeChild(element)
    }

    render() {
        return (
            <>
                {this.state.error && (
                    <div className="alerts">
                        <Alert variant="danger" onClose={() => this.setState({error: false})}>
                            {this.state.error}
                        </Alert>
                    </div>
                )}
                {!this.state.licenseKeys ? (
                    <Grid container={true}>
                        <Grid item={true} xs={12} sm={12}>
                            <AvantPaper style={{maxWidth: 512, justifyContent: "center"}}>
                                {this.state.states && (
                                    <div>
                                        <FormGroup style={{margin: theme.spacing(2)}}>
                                            <FormLabel>State</FormLabel>
                                            <Select
                                                placeholder={""}
                                                value={this.state.state ? this.state.state.toString() : undefined}
                                                onChange={this.handleStateChange}
                                                data-tst-id={"state"}
                                            >
                                                {this.state.states &&
                                                    this.state.states.map((state: GroupMaster) => {
                                                        return (
                                                            <MenuItem key={state.id} value={state.id}>
                                                                {state.name}
                                                            </MenuItem>
                                                        )
                                                })}
                                            </Select>
                                        </FormGroup>

                                        <FormGroup style={{margin: theme.spacing(2)}}>
                                            <FormLabel>District</FormLabel>
                                            <Select
                                                placeholder={""}
                                                value={this.state.districtId ? this.state.districtId.toString() : undefined}
                                                onChange={this.handleDistrictChange}
                                                data-tst-id={"district"}
                                            >
                                                {this.state.districts &&
                                                this.state.districts.map((district: GroupMaster) => {
                                                    return (
                                                        <MenuItem key={district.id} value={district.id}>
                                                            {district.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormGroup>

                                        <FormGroup style={{margin: theme.spacing(2)}}>
                                            <FormLabel>School</FormLabel>
                                            <Select
                                                placeholder={""}
                                                value={this.state.districtId && this.state.schoolId ? this.state.schoolId.toString() : undefined}
                                                onChange={this.handleSchoolChange}
                                                data-tst-id={"school"}
                                            >
                                                {this.state.schools &&
                                                this.state.schools.map((school: GroupMaster) => {
                                                    return (
                                                        <MenuItem key={school.id} value={school.id}>
                                                            {school.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                )}

                                <FormGroup style={{margin: theme.spacing(2)}}>
                                    <FormLabel>Opportunity code</FormLabel>
                                    <div className="error" hidden={!this.state.formErrors.oppCode}>
                                        {this.state.formErrors.oppCode.join(" ") || ""}
                                    </div>
                                    <TextField
                                        type="text"
                                        placeholder=""
                                        value={this.state.oppCode}
                                        onChange={this.handleOppCodeChange}
                                        data-tst-id="oppCode"
                                    />
                                </FormGroup>

                                <FormGroup style={{margin: theme.spacing(2)}}>
                                    <FormLabel>Number of License Keys</FormLabel>
                                    <div className="error" hidden={!this.state.formErrors.numKeys}>
                                        {this.state.formErrors.numKeys.join(" ") || ""}
                                    </div>
                                    <TextField
                                        type="text"
                                        placeholder=""
                                        value={this.state.numKeys ? this.state.numKeys.toString() : undefined}
                                        onChange={this.handleNumKeysChange}
                                        data-tst-id="numKeys"
                                    />
                                </FormGroup>

                                <FormGroup style={{margin: theme.spacing(2)}}>
                                    <FormLabel>Number of Languages</FormLabel>
                                    <div className="error" hidden={!this.state.formErrors.languageCount}>
                                        {this.state.formErrors.languageCount.join(" ") || ""}
                                    </div>
                                    <TextField
                                        type="text"
                                        placeholder=""
                                        value={this.state.languageCount ? this.state.languageCount.toString() : undefined}
                                        onChange={this.handleLanguageCountChange}
                                        data-tst-id="languageCount"
                                    />
                                </FormGroup>

                                <FormGroup style={{margin: theme.spacing(2)}}>
                                        <DatePicker
                                            label={"Expiration Date"}
                                            value={this.state.expirationDate ? this.state.expirationDate : undefined}
                                            onChange={this.handleExpirationChange}
                                            data-tst-id="keyExpirationDate"
                                            disablePast={true}
                                        />
                                </FormGroup>

                                <Button
                                    onClick={this.submit}
                                    color={"primary"}
                                    variant={"contained"}
                                    style={{margin: theme.spacing(2)}}
                                    data-tst-id="submit"
                                >
                                    Submit
                                </Button>
                            </AvantPaper>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container={true}>
                        <Grid item={true} xs={12} sm={12} style={{margin: theme.spacing(2)}}>
                            <AvantPaper style={{maxWidth: 512, justifyContent: "center", alignContent: "center", textAlign: "center"}}>
                                <List style={{padding: theme.spacing(2),textAlign: "center"}} >
                                    {this.state.licenseKeys.map((licenseKey: ApiLicenseKey) => {
                                        return <ListItem
                                            data-tst-id="license-key"
                                            key={licenseKey.key} style={{display: "flex", justifyContent: "center"}}>{licenseKey.key}</ListItem>
                                    })}
                                </List>

                                <Button
                                    onClick={this.download}
                                    color={"primary"}
                                    variant={"contained"}
                                    style={{margin: theme.spacing(2)}}
                                >
                                    Download License Keys
                                </Button>
                            </AvantPaper>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }
}

