/*******************************************************
 * Copyright (C) 2010-Present Avant Assessment
 * All Rights Reserved
 *******************************************************/
import DeleteIcon from "@material-ui/icons/Delete"
import {AddCircle} from "@mui/icons-material"
import {FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material"
import React, {ChangeEvent, memo, useEffect, useState} from "react"
import {LoadingSpinner} from "../../../../../app/common/loaders/LoadingSpinner"
import ApiService from "../../../../../services/ApiService"
import {ProductPackage, SSOLicenseCount} from "../../../../../types/types"
import {findInsertPosition} from "../utilities/UtilityFunctions"

interface LicensesComponentProps {
    licensesSold: SSOLicenseCount[]
    addNewProductPackageLicense: (license: SSOLicenseCount) => void
    removeProductPackageLicense: (license: string) => void
}

const initialLicenseValue: SSOLicenseCount =         {
    productPackage: null,
    count: 0,
    price: 0
}

const LicensesComponent: React.FC<LicensesComponentProps> = ({licensesSold, addNewProductPackageLicense, removeProductPackageLicense}) => {

    const [productPackages, setProductPackages] = useState<ProductPackage[]>()
    const [availableProductPackages, setAvailableProductPackages] = useState<ProductPackage[]>()
    const [loadingData, setLoadingData] = useState<boolean>(false)
    const [newLicense, setNewLicense] = useState<SSOLicenseCount>(initialLicenseValue)

    useEffect(() => {
        setLoadingData(true)
        ApiService.getListOfProductPackages().then((resp) => {
            setProductPackages(resp)
            setAvailableProductPackages(resp)
            setLoadingData(false)
        })
    }, [])

    const handlePackageSelected = (event: SelectChangeEvent) => {
        setNewLicense({...newLicense, productPackage: event.target.value})
    }

    const handleCountChange = (fieldName: keyof SSOLicenseCount) => (event: ChangeEvent<HTMLInputElement>) => {
        setNewLicense({...newLicense, [fieldName]: event.target.value})
    }

    const addButtonEnabled = (): boolean => {
        return newLicense.count !== null && newLicense.price !== null && newLicense.productPackage !== null && newLicense.productPackage.trim() !== ""
    }

    const handleDeletePackage = (license: string) => {
        removeProductPackageLicense(license)
        if (availableProductPackages !== null && availableProductPackages !== undefined) {
            // This is a safety check, we expect it to be null, as I removed it from the list when the license was added
            const availablePackagesContains = availableProductPackages.find(pp => pp.code === license)
            if (availablePackagesContains) {
                // This means it was not properly remove, so do nothing
            } else {
                try {
                    const itemToReAdd = productPackages?.find(pp => pp.code === license)
                    const insertIndex = findInsertPosition(availableProductPackages, itemToReAdd, (a, b) => a!.code.localeCompare(b!.code))

                    if (itemToReAdd) {
                        // const newAvailablePackagaArray = availableProductPackages.splice(insertIndex, 0, itemToReAdd)
                        // setAvailableProductPackages(newAvailablePackagaArray)
                        availableProductPackages.splice(insertIndex, 0, itemToReAdd)

                        setAvailableProductPackages([...availableProductPackages])
                    }
                } catch (e) {
                    console.log(`EROROR: ${e}`)
                }
            }
        }
    }

    const addProductPackageClicked = () => {
        // TODO: Add license to parent
        const count = newLicense && newLicense.count !== null && newLicense.count > 0 ? newLicense.count : 1
        const price = newLicense && newLicense.price !== null && newLicense.price > 0 ? newLicense.price : 1000
        const newLic: SSOLicenseCount = {productPackage: newLicense.productPackage, count: count, price: price}
        addNewProductPackageLicense(newLic)
        // Update the parent modal with the new license
        setNewLicense(initialLicenseValue)
        // we need to make it so you cant add a second license of the same product package
        if (availableProductPackages !== null && availableProductPackages !== undefined) {
            const curAvailablePackages = availableProductPackages.filter(pp => pp.code !== newLic.productPackage)
            setAvailableProductPackages(curAvailablePackages)
        }
    }

    return (
        <>
            {
                licensesSold.length > 0 && (
                    <Grid container={true} spacing={2}>
                        {licensesSold.map((lic, index) => {
                            return (
                                <React.Fragment key={`${lic.productPackage}-${index}`}>
                                    <Grid item={true} xs={3}>
                                        <TextField
                                            disabled={true}
                                            margin="dense"
                                            id="product-package-lic"
                                            label="Product Package"
                                            type="text"
                                            fullWidth={true}
                                            variant="outlined"
                                            value={lic.productPackage}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={3}>
                                        <TextField
                                            disabled={true}
                                            margin="dense"
                                            id="product-package-count-lic"
                                            label="License Count"
                                            type="number"
                                            fullWidth={true}
                                            variant="outlined"
                                            value={lic.count}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={3}>
                                        <TextField
                                            disabled={true}
                                            margin="dense"
                                            id="product-package-cost-lic"
                                            label="cost"
                                            type="number"
                                            fullWidth={true}
                                            variant="outlined"
                                            value={lic.price}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <IconButton
                                            aria-label={`delete-${index}-${lic.productPackage}`}
                                            size="large"
                                            onClick={() => handleDeletePackage(lic.productPackage!)}
                                        >
                                            <DeleteIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                )
            }
            {
                loadingData ? (
                    <LoadingSpinner />
                ) : (
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={3}>
                            <FormControl fullWidth={true} margin="dense">
                                <InputLabel id="product-package-select-label">Product Package</InputLabel>
                                <Select
                                    labelId="product-package-select-label"
                                    id="product-package-select"
                                    label="Product Package"
                                    value={newLicense.productPackage ? newLicense.productPackage : ""}
                                    margin="dense"
                                    onChange={handlePackageSelected}
                                >
                                    {
                                        availableProductPackages && availableProductPackages.length > 0 && availableProductPackages.map((pp, index) => {
                                            return (
                                                <MenuItem
                                                    key={`${index}-${pp.code}-${pp.id}`}
                                                    value={pp.code}
                                                >
                                                    {pp.code} - {pp.shortName}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item={true} xs={3}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    // disabled={true}
                                    margin="dense"
                                    id="product-package-count-lic"
                                    label="License Count"
                                    type="number"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={handleCountChange("count")}
                                    value={newLicense.count ? newLicense.count : "" }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={3}>
                            <FormControl fullWidth={true}>
                                <TextField
                                    margin="dense"
                                    id="product-package-cost-lic"
                                    label="License Cost"
                                    type="number"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={handleCountChange("price")}
                                    value={newLicense.price ? newLicense.price : "" }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <IconButton
                                aria-label="add-new-license"
                                size="large"
                                onClick={addProductPackageClicked}
                                disabled={!addButtonEnabled()}
                            >
                                <AddCircle fontSize="inherit" color="success" />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            }
        </>
    )

}

export default memo(LicensesComponent)
